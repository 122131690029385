<template>
  <div class="comments-wrapper">
    <template v-if="commentsDataArray.length > 0">
      <div class="comments">
        <div
          v-for="comment in commentsDataArray"
          :key="comment.id"
          :class="comment.type"
        >
          <img
            v-if="comment.userAvatar !== '' && comment.userAvatar !== null"
            :src="getImgUrl(comment.userAvatar)"
            alt=""
          />
          <img v-else :src="getImgUrl('default-image.png')" alt="" />

          <span class="comment-boady">
            <span class="user-name">{{ comment.name }}:</span>
            <img
              v-if="comment.userImage !== ''"
              :src="getImgUrl(comment.userImage)"
              alt=""
            />
            <span class="comment-text" v-html="comment.comment" />
          </span>
        </div>
      </div>
    </template>
    <div class="no-comments" v-else>No comments.</div>

    <div class="comment-footer">
      <wysiwyg v-model="selectedCommentData.comment" />
      <a href="javascript:;"
        ><b-icon
          @click="openUploadModal()"
          icon="paperclip"
          scale="1.5"
          aria-hidden="true"
        ></b-icon
      ></a>
      <button @click="addAcomment()">Add comment</button>
    </div>

    <b-modal
      id="uploadImage"
      size="lg"
      title="Upload"
      :hide-footer="Boolean(true)"
    >
      <ImageUploader
        @populateImageObjectFromUpload="imageUploadFileDataChnged($event)"
        @populateImageFileDataFromUpload="imageUploadObjectDataChanged($event)"
        @clearImageDataFromUpload="clearImageDataFromUpload()"
        :imageURL="selectedCommentData.userImage"
      />

      <wysiwyg v-model="selectedCommentData.comment" />
      <button @click="addAcomment()">Add comment</button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CHECK_PROFILE_HAS_DATA } from "@/store/actions/user.js";
import { Comments } from "@/services/comments-service.js";
import { Users } from "@/services/user-service.js";
import ImageUploader from "../../components/ImageUploader";
import moment from "moment";

export default {
  name: "Comments",
  components: {
    ImageUploader,
  },
  data: function () {
    return {
      allUsers: [],
      commentsDataArray: [],
      selectedCommentData: {
        user_id: null,
        comment: "",
        date: null,
        type: "User",
        type_id: null,
        teamId: null,
        userImage: "",
      },
      userData: {
        name: "",
        userType: "",
      },
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null,
      },
    };
  },
  props: {
    commentsData: {
      type: Array,
      default: null,
    },
    userAndTypeIds: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  watch: {
    commentsData: function (newValue, oldValue) {
      if (newValue !== null) {
        this.getAllUsersNamesByClubId();
        this.commentsDataArray = newValue;
      }
    },
  },
  async created() {
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);
    if (response === true) {
      this.setUser();
      this.setUserAndTypeIds();
      this.getAllUsersNamesByClubId();
      this.commentsDataArray = this.commentsData;
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );
      this.getUserById(clientId);
    }
  },
  methods: {
    clearImageDataFromUpload() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
    },
    imageUploadObjectDataChanged(data) {
      this.image = data;
    },
    imageUploadFileDataChnged(data) {
      this.imageFile = data;
    },
    openUploadModal() {
      this.showModal("uploadImage");
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    addUserDetailsToEachComment() {
      const newArray = this.commentsData.map((commentData) => {
        const userData = this.allUsers.filter((user) => {
          return commentData.user_id == user.id;
        });

        return {
          ...commentData,
          name: userData[0].name,
          userAvatar: userData[0].userImage,
        };
      });

      this.commentsDataArray = newArray;
    },
    async getAllUsersNamesByClubId() {
      this.allUsers = [];
      const userData = {
        clubId: this.userAndTypeIds.clubId,
        action: "getAllUsersNamesByClubId",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.allUsers = responce.data;
        this.addUserDetailsToEachComment();
      }
    },
    populateForAdminComment() {
      if (this.userData.userType === "Admin") {
        this.selectedCommentData.type = "Admin";
        this.selectedCommentData.user_id = this.userData.id;
      } else {
        this.setUserAndTypeIds();
      }
    },
    setUserAndTypeIds() {
      this.selectedCommentData.user_id = this.userAndTypeIds.user_id;
      this.selectedCommentData.type_id = this.userAndTypeIds.type_id;
      this.selectedCommentData.teamId = this.userAndTypeIds.teamId;
    },
    async addAcomment() {
      this.selectedCommentData.date = this.changeTimeToTimeAndDate(
        moment().format("HH:mm"),
        moment().format("YYYY-MM-DD")
      );
      this.populateForAdminComment();
      if (this.image.imageFile !== null && this.imageFile !== null) {
        this.selectedCommentData.fileName = this.imageFile.imageName;
        this.selectedCommentData.imageFile = this.image;
      }
      const userData = {
        ...this.selectedCommentData,
        action: "saveComment",
      };
      const responce = await Comments.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.$emit("getComments", this.selectedCommentData.type_id);
        this.hideModal("uploadImage");
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        this.setUserAndTypeIds();
        this.commentsDataArray = this.commentsData;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    changeTimeToTimeAndDate(timeString, date) {
      return `${date}T${timeString}:00.000Z`;
    },
    showModal(modelId) {
      this.$bvModal.show(modelId);
    },
    hideModal(modelId) {
      this.$bvModal.hide(modelId);
    },
  },
};
</script>

<style scoped lang="scss">
.comments-wrapper {
  position: relative;
  width: 100%;

  .no-comments {
    padding: 1rem;
  }

  .comments {
    min-height: 5rem;
    overflow: scroll;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 1rem;

    .comment-text {
      padding: 0;
      padding-top: 2rem;
    }

    .comment-boady {
      position: relative;

      img {
        border: none;
        border-radius: 0;
        display: block;
        margin-top: 2rem;
        width: auto;
        max-width: 800px;
      }

      .user-name {
        position: absolute;
        padding: 0;
        border: none;
        font-size: 0.8rem;
      }
    }

    .person {
      border-radius: 100px;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }

    div {
      margin: 1rem;
    }

    div span {
      padding: 1rem;
      width: fit-content;
      border-radius: 10px;
      color: white;
      display: inline-block;
    }

    div .user-name {
      border: 1px solid red;
    }

    div img {
      border: 1px solid #c3cdd5;
      width: 3rem;
      border-radius: 100px;
      margin-right: 1rem;
    }

    div.User span {
      background: #67b1ef;
    }

    div.Admin span {
      background: #4fa971;
    }
  }

  .comment-footer {
    textarea {
      width: 100%;
      min-height: 5rem;
    }

    button {
      font-size: 0.7rem;
      line-height: 1rem;
      width: 8rem;
      height: 3rem;
      margin-top: 1rem;
      margin-left: 0.5rem;
    }
  }
}

.editr--toolbar {
  display: none;
}
</style>
