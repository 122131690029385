<template>
  <div class="intro-header">
    <img class="main-image-top" src="./img/pitch.svg" alt="pitch" />

    <!-- User photo and main header -->

    <div class="user-photo-info">
      <template
        v-if="
          selectedUserData !== null &&
          selectedUserData !== undefined &&
          Object.keys(selectedUserData).length !== 0
        "
      >
        <img
          v-if="
            selectedUserData.userImage !== '' &&
            selectedUserData.userImage !== null
          "
          id="user_photo"
          :src="getImgUrl(selectedUserData.userImage)"
          alt=""
        />
        <img v-else :src="getImgUrl('default-image.png')" alt="" id="user_photo" />
      </template>
      <template v-else>
        <img
          v-if="
            userDataForHeader.userImage !== '' &&
            userDataForHeader.userImage !== null
          "
          id="user_photo"
          :src="getImgUrl(userDataForHeader.userImage)"
          alt=""
        />
        <img v-else :src="getImgUrl('default-image.png')" alt="" />
      </template>

      <template
        v-if="
          selectedUserData !== null &&
          selectedUserData !== undefined &&
          Object.keys(selectedUserData).length !== 0
        "
      >
        <h1 v-if="selectedUserData.userType === 'User'">
          <span v-if="sectionSelected === 'Player Profile'"
            > Player Profile</span
          >
          <span v-if="sectionSelected === 'Training Plan'"
            >Personal Programmes</span
          >
          <br />
          for {{ selectedUserData.name }}
        </h1>
      </template>
      <template v-else>
        <h1>{{ userDataForHeader.name }}</h1>
      </template>
    </div>

    <div class="sessons-header">
      <div class="form">
        <div class="form__item">
          <div
            v-if="
              (userDataForHeader.userType === 'Admin' ||
                userDataForHeader.userType === 'Coach') &&
              $route.name !== 'home' &&
              $route.name !== 'profile' &&
              $route.name !== 'ptHome' &&
              $route.name !== 'coaches' &&
              $route.name !== 'documents'
            "
            class="select-user"
          >
            <label class="form__label" for="userSelect">Change user</label>
            <select
              @change="setSelectedUserOfTrainingPlan($event)"
              id="userSelect"
            >
              <option value="" disabled selected>Select user</option>
              <option
                :value="user.id"
                v-for="user in coachesUsersData"
                :key="user.id"
              >
                {{ user.name }}
              </option>
            </select>
          </div>

          <div
            v-if="
              (userDataForHeader.userType === 'Admin' ||
                userDataForHeader.userType === 'Coach') &&
              $route.name !== 'home' &&
              $route.name === 'profile' &&
              $route.name !== 'coaches' &&
              $route.name !== 'documents' &&
              $route.name !== 'ptHome'
            "
            class="select-user"
          >
            <!--<b-input-group size="sm" class="mb-2 autocomplete">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                type="search"
                placeholder="Search players"
                @input="lookUpSearch($event)"
              ></b-form-input>
              <ul
                class="autocomplete-results"
                v-if="filterdCoachesUsersData.length > 0"
              >
                <li
                  @click="setSelectedUserOfTrainingPlan(userData)"
                  v-for="userData in filterdCoachesUsersData"
                  :key="userData.id"
                  class="autocomplete-result"
                >
                  {{ userData.name }}
                </li>
              </ul>
            </b-input-group>-->
          </div>

          <!-- training plan look up -->

          <!--<div
            v-if="
              (userDataForHeader.userType === 'Admin' ||
                userDataForHeader.userType === 'Coach') &&
              $route.name !== 'ptHome'
            "
            class="select-user"
          >
            <b-input-group size="sm" class="mb-2 autocomplete">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                type="search"
                placeholder="Search players"
                @input="lookUpSearch($event)"
              ></b-form-input>
              <ul
                class="autocomplete-results"
                v-if="filterdCoachesUsersData.length > 0"
              >
                <li
                  @click="openTrainingPlanPageCall(userData.id)"
                  v-for="userData in filterdCoachesUsersData"
                  :key="userData.id"
                  class="autocomplete-result"
                >
                  {{ userData.name }}
                </li>
              </ul>
            </b-input-group>
          </div>-->

          <!-- coach look up -->

          <div
            v-if="
              (userDataForHeader.userType === 'Admin' ||
                userDataForHeader.userType === 'Coach') &&
              $route.name === 'coaches'
            "
            class="select-user"
          >
            <h1>Coaches</h1>
            <b-input-group size="sm" class="mb-2 autocomplete">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                type="search"
                placeholder="Search coaches"
                @input="lookUpSearch($event)"
              ></b-form-input>
              <ul
                class="autocomplete-results"
                v-if="filterdCoachesData.length > 0"
              >
                <li
                  @click="openTrainingPlanPageCall(userData.id)"
                  v-for="userData in filterdCoachesData"
                  :key="userData.id"
                  class="autocomplete-result"
                >
                  {{ userData.name }}
                </li>
              </ul>
            </b-input-group>
          </div>

          <div
            class="select-user"
            v-if="
              (userDataForHeader.userType === 'Admin' ||
                userDataForHeader.userType === 'Coach') &&
              $route.name === 'documents'
            "
          >
            <h1>Documents library</h1>
          </div>
        </div>
      </div>

      <h2 v-if="userDataForHeader.userType === 'User'">
        {{ sectionSelected }}
        <span
          v-if="sectionSelected !== 'Player Profile'"
          class="week-selected-dates-text"
        >
          Week number: {{ weekSelectedNumber }} |
          {{ selectedWeekMonSunday }}</span
        >

        <div
          v-if="sectionSelected === 'Player Profile'"
          class="player-profile-header"
        >
          <p><span>Name:</span> {{ userDataForHeader.name }}</p>
          <p><span>Dob:</span> {{ dayMonthYear(userDataForHeader.dob) }}</p>
          <p><span>Position:</span> {{ userDataForHeader.position }}</p>
          <p><span>Team:</span> {{ userDataForHeader.teamId }}</p>
          <p v-if="checkSignedDate(userDataForHeader.joined_date)">
            <span>Signed:</span>
            {{ dayMonthYear(userDataForHeader.joined_date) }}
          </p>

          <p v-if="pathWayCheck(userDataForHeader.age_group) === 'Foundation'">
            <span>Phase:</span>
            {{ pathWayCheck(userDataForHeader.age_group) }}
          </p>
          <p
            v-if="
              pathWayCheck(userDataForHeader.age_group) === 'Youth Development'
            "
          >
            <span>Phase:</span>
            {{ pathWayCheck(userDataForHeader.age_group) }}
          </p>
          <p
            v-if="
              pathWayCheck(userDataForHeader.age_group) ===
              'Professional Development'
            "
          >
            <span>Phase:</span>
            {{ pathWayCheck(userDataForHeader.age_group) }}
          </p>
        </div>
      </h2>
      <div v-else class="header-buttons">
        <h2><img v-if="sectionSelected === 'Player Profile'" id="small_shirt" src="./img/player-shirt-small.jpg" /> {{ sectionSelected }}</h2>
        <span
          v-if="
            $route.name !== 'home' &&
            $route.name !== 'ptHome' &&
            $route.name !== 'coaches' &&
            $route.name !== 'documents'
          "
          class="week-selected-dates-text"
        >
          <span class="selected-user"
            >Selected user:
            <span
              v-if="selectedTrainingUserData !== null && selectedTrainingUserData !== undefined"
              class="highlighted-text"
            >
              {{ selectedTrainingUserData.name }}
            </span>
          </span>

          <span class="week-number">
            Position: {{ selectedTrainingUserData.position }}</span
          >
          <span class="week-number">
            Week number: {{ weekSelectedNumber }} |
            {{ selectedWeekMonSunday }}</span
          >

          <span
            v-if="
              selectedUserData !== null &&
              selectedUserData !== undefined &&
              Object.keys(selectedUserData).length !== 0
            "
            class="season-dates"
            >Season: {{ dayMonthYear(currentPlayingseasonStartDate()) }} -
            {{ dayMonthYear(currentPlayingseasonEndDate()) }}</span
          ></span
        >
      </div>
    </div>
    <div class="playerPerformaceScoreWrapper" v-if="
    $route.name !== 'ptHome' 
    && $route.name !== 'ptUserHome'
    && $route.name !== 'trainingPlans'
    && userDataForHeader.userType !== 'Admin' 
    && userDataForHeader.userType !== 'Coach'">

      <!--<CircularPercentage
      v-if="allplayerPerformanceReportData.length > 0"
        :review="null"
        :allPlayerReviews="allplayerPerformanceReportData"
        circularType="muilti"
      />-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  POPULATE_WEEK_SELECTED_NUMBER,
  POPULATE_SELECTED_CALENDAR_DATE,
  POPULATE_MON_TO_FRI_DATE,
  POPULATE_COACHES_USER_DATA,
} from "@/store/actions/user.js";
import { PerformanceReview } from "@/services/performance-review-service.js";
// import CircularPercentage from "../../components/CircularPercentage";
import { Teams } from "@/services/teams-service.js";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import moment from "moment";
export default {
  mixins: [extendSeasonEndDateToFullYearMixin],
  data() {
    return {
      weeklyDate: "",
      weekSelectedNumber: "",
      sectionSelected: "",
      selectedTrainingUserData: null,
      selectedWeekMonSunday: "",
      userData: null,
      filterdCoachesUsersData: [],
      filterdCoachesData: [],
      currentPlayingSeason: null,
      allplayerPerformanceReportData: [],
      playerPerformanceReportDataSummary: {
        attitude: "4",
        leadership: "3",
        mentality: "2",
        physique: "4",
        professionalism: "3",
        skill: "2",
      },
      playerPerformanceReportDataSummaryArrays: {
        attitude: [],
        skill: [],
        physique: [],
        mentality: [],
        leadership: [],
        professionalism: [],
      },
      selectedUserTeamData: null,
      season_start_date: null,
      season_end_date: null,
      showPlayerPerformaceReviewAvrageScore: false,
    };
  },
  props: {
    userDataForHeader: {
      type: Object,
    },
    coachesUsersData: {
      type: Array,
    },
    selectedUserData: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters([
      "getWeekNumber",
      "getProfile",
      "getSelectedCalendarDateValue",
    ]),
  },
  watch: {
    selectedUserData: {
      immediate: true,
      deep: true,
      handler: function (newValue, oldValue) {
        this.getPlayerPerformanceReviews(this.userDataForHeader.id);
        if (newValue !== null) {
          this.selectedTrainingUserData = newValue;
        }
      },
    },
    userDataForHeader: {
      immediate: true,
      deep: true,
      handler: function (newValue, oldValue) {
        if (this.userDataForHeader.userType === "User") {
          this.getPlayerPerformanceReviews(this.userDataForHeader.id);
          this.showPlayerPerformaceReviewAvrageScore = true;
        }
      },
    },
  },
  created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    this.setPlayingSeasion();
    this.setMonToSundayWeekDates();

    this.setSection(this.$route.name);
    // this.checkIfWeekNumberHasBeenSet();
    this.setUser();
    if (this.$route.params.trainingPlanUserId !== 0) {
      this.coachesUsersData.length > 0
        ? this.setSelectedTrainingPlanUser(
            this.$route.params.trainingPlanUserId
          )
        : this.setCoachesUsersData(this.$route.params.trainingPlanUserId);
    }
  },
  methods: {
    currentPlayingseasonStartDate() {
      return localStorage.getItem("currentPlayingseasonStartDate");
    },
    currentPlayingseasonEndDate() {
      return localStorage.getItem("currentPlayingseasonEndDate");
    },
    async getPlayerPerformanceReviews(id) {
      let userIdData =
        this.selectedUserData !== null &&
        this.selectedUserData !== undefined &&
        Object.keys(this.selectedUserData).length !== 0
      ? this.selectedUserData.id
      : this.userDataForHeader.id;

      const userData = {
        userId: userIdData,
        season_start_date: localStorage.getItem(
          "currentPlayingseasonStartDate"
        ),
        season_end_date: this.extendSeasonEndDateToFullYear(),
        action: "getPerformanceReviews",
      };
      const responce = await PerformanceReview.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allplayerPerformanceReportData = responce.data;
      }
    },
    pathWayCheck(year) {
      const currentYear = moment().year();
      if (currentYear - year > 9 && currentYear - year < 11) {
        return "Foundation";
      }
      if (currentYear - year > 11 && currentYear - year < 16) {
        return "Youth Development";
      }
      if (currentYear - year > 16 && currentYear - year < 21) {
        return "Professional Development";
      }
    },
    checkSignedDate(date) {
      if (date === null && date === undefined) {
      } else {
        return date;
      }
    },
    openTrainingPlanPageCall(userId) {
      const data = [userId, "Admin", "Training Plan"];
      this.$emit("callToOpenTrainingPlan", { ...data });
    },
    setPlayingSeasion() {
      if (this.weekSelectedNumber >= 8 && this.weekSelectedNumber < 52) {
        this.currentPlayingSeason = moment().format("YYYY");
      }
      if (this.weekSelectedNumber >= 1 && this.weekSelectedNumber < 8) {
        this.currentPlayingSeason = moment().subtract(1, "year").format("YYYY");
      }
      if (this.weekSelectedNumber == 52) {
        moment().format("M") === "1"
          ? (this.currentPlayingSeason = moment()
              .subtract(1, "year")
              .format("YYYY"))
          : (this.currentPlayingSeason = moment().format("YYYY"));
      }

      localStorage.setItem("currentPlayingSeason", this.currentPlayingSeason);
    },
    lookUpSearch(value) {
      if (value.length > 3) {
        this.filterdCoachesUsersData = [];

        const results = Array.from(value).reduce(
          (a, v, i) => `${a}[^${value.substr(i)}]*?${v}`,
          ""
        );
        const regEx = RegExp(results);

        this.coachesUsersData.filter((v) => {
          if (v.name.match(regEx)) {
            this.filterdCoachesUsersData.push(v);
          }
        });
      } else {
        this.filterdCoachesUsersData = [];
      }
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    checkforDotAtTheStartOfUrl(p) {
      return p.startsWith(".") ? p.substring(1) : p;
    },
    checkTheimageForBlob(p, imageNumber) {
      if (p !== null) {
        var URL = this.checkforDotAtTheStartOfUrl(p);
      }
      switch (typeof URL) {
        case "string":
          URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
          return URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
        case "object":
          return this[imageNumber].path;
      }
    },
    getPath(p, imageNumber) {
      if (p === undefined) {
        return;
      }
      return this.checkTheimageForBlob(p, imageNumber);
    },
    setSelectedUserOfTrainingPlan(e) {
      let userId = null;
      this.filterdCoachesUsersData = [];
      e.target === undefined ? (userId = e) : (userId = e.target.value);

      this.coachesUsersData.length > 0
        ? this.setSelectedTrainingPlanUser(userId)
        : this.setCoachesUsersData(userId);
      // const selectedUser = e;
      this.$emit("selectedUseChanged", { ...e });
    },
    setSelectedTrainingPlanUser(id) {
      let arrayResult = this.coachesUsersData.filter((selectedTrainingUser) => {
        return selectedTrainingUser.id == id;
      });
      if (arrayResult.length !== 0) {
        this.selectedTrainingUserData = arrayResult[0];
      }
    },
    populateCalendarDateWithCurrentWeek() {
      const now = moment().format("MM-DD-YYYY");
      this.weekSelectedNumber = moment(now).isoWeek();
      this.weeklyDate = now;
      this.despachTheCalendarActionSetLocalStorage();
      this.despachTheWeekActionSetLocalStorage();
    },
    setUser() {
      if (Object.keys(this.getProfile).length == 0) {
        this.userData = this.getProfile;
      }
    },
    setSection(sectionName) {
      switch (sectionName) {
        case "home":
          this.sectionSelected = "Dashboard";
          break;
        case "trainingPlans":
          this.sectionSelected =
            this.$route.params.trainingPlanType === "All"
              ? "Players training plans"
              : this.$route.params.trainingPlanType;
          break;
        case "ptUserHome":
          this.sectionSelected = "Training Plan";
          break;
        case "ptHome":
          this.sectionSelected = "Training Plan";
          break;
        case "profile":
          this.sectionSelected = "Player Profile";
          break;
      }
    },
    depatchMonToSundayWeekDatesInLocalStorage() {
      const monToFri = this.selectedWeekMonSunday;
      localStorage.setItem("mondaySunDates", this.selectedWeekMonSunday);
      this.$store.dispatch(
        POPULATE_MON_TO_FRI_DATE,
        this.selectedWeekMonSunday
      );
      this.$emit("mondaySunDatesChanged", { monToFri });
    },
    setMonToSundayWeekDates() {
      const year = JSON.stringify(moment().year());
      const monday = moment()
        .year(year)
        .isoWeek(this.weekSelectedNumber)
        .day("monday")
        .startOf("day");
      this.selectedWeekMonSunday =
        monday.format("MMM D - ") + monday.add(6, "days").format("D YYYY");
      this.depatchMonToSundayWeekDatesInLocalStorage();
    },
    setCalendarDate() {
      const year = JSON.stringify(moment().year());
      this.weeklyDate = moment(year)
        .add(this.weekSelectedNumber, "weeks")
        .format("DD MMM yyyy");
    },
    setTheWeekNumber(date) {
      const now = moment().format("MM-DD-YYYY");
      this.weekSelectedNumber = moment(date).isoWeek();
      this.setMonToSundayWeekDates();
      // this.setCalendarDate();
      this.despachTheWeekActionSetLocalStorage();
    },
    setSelectedDate() {
      this.weeklyDate = moment().format("MM-DD-YYYY");
      this.setTheWeekNumber(this.weeklyDate);
    },
    checkIfWeekNumberHasBeenSet() {
      if (this.getWeekNumber !== null && this.getWeekNumber !== "") {
        this.weekSelectedNumber = this.getWeekNumber;
        this.setCalendarDate();
        this.setMonToSundayWeekDates();
        this.despachTheWeekActionSetLocalStorage();
      } else {
        this.checkIfWeekNumberHasBeenSetInLocalStorage();
      }
    },
    despachTheCalendarActionSetLocalStorage() {
      const weeklyDate = this.weeklyDate;
      localStorage.setItem("calendarDate", this.weeklyDate);
      this.$store.dispatch(POPULATE_SELECTED_CALENDAR_DATE, this.weeklyDate);
      this.$emit("calendarDateChanged", { weeklyDate });
    },
    checkIfWeekNumberHasBeenSetInLocalStorage() {
      const weekNumber = localStorage.getItem("weekNumber");
      if (weekNumber !== null && weekNumber !== "") {
        this.weekSelectedNumber = JSON.parse(weekNumber);
        this.setMonToSundayWeekDates();
        this.despachTheWeekActionSetLocalStorage();
      } else {
        this.setSelectedDate();
      }
    },
    despachTheWeekActionSetLocalStorage() {
      const weekSelectedNumber = this.weekSelectedNumber;
      this.$store.dispatch(
        POPULATE_WEEK_SELECTED_NUMBER,
        this.weekSelectedNumber
      );
      localStorage.setItem("weekNumber", weekSelectedNumber);
      this.$emit("weekSelectedChanged", { weekSelectedNumber });
    },
    setCoachesUsersData(id) {
      this.$store.dispatch(
        POPULATE_COACHES_USER_DATA,
        JSON.parse(localStorage.getItem("coachesUsersData"))
      );
      this.$emit("getAllUsersForThisAdmin");
      this.setSelectedTrainingPlanUser(id);
    },
    changeDateFormat(date) {
      return moment(date).format("D/MM/YYYY");
    },
    dayMonthYear(date) {
      return moment(date).format("D MMM YYYY");
    },
  },
};
</script>
