export default {
    methods: {
        goToPlayer(playerData) {
            if (
                this.userData.userType === "Admin" ||
                this.userData.userType === "Coach") {
                this.$router.push({
                    name: "profile",
                    params: { playerData: playerData },
                });
            }
        }
    }
}