<template>
  <div v-if="weekSelectedNumber !== null" class="sessions-info">
    <a
      href="javascript:;"
      @click="openTrainingPlanPage(userData.id, 'User', 'Training Plan')"
      class="session-card"
    >
      <img src="./img/training-background.jpg" alt="" />
      <p class="small-date">week {{ weekSelectedNumber }}</p>
      <h3>Training Plan | {{ selectedWeekMonSunday }}</h3>
    </a>

    <a
      href="javascript:;"
      @click="openTrainingPlanPage(userData.id, 'User', 'Mental training')"
      class="session-card"
    >
      <img src="./img/mental-training-background.jpg" alt="" />
      <p class="small-date">week {{ weekSelectedNumber }}</p>
      <h3>Mental training | {{ selectedWeekMonSunday }}</h3>
    </a>
    <a
      href="javascript:;"
      @click="openTrainingPlanPage(userData.id, 'User', 'Nutrition')"
      class="session-card"
    >
      <img src="./img/newt-training-background.jpg" alt="" />
      <p class="small-date">week {{ weekSelectedNumber }}</p>
      <h3>Nutrition | {{ selectedWeekMonSunday }}</h3>
    </a>
    <a
      href="javascript:;"
      @click="openTrainingPlanPage(userData.id, 'User', 'Recovery')"
      class="session-card"
    >
      <img src="./img/recovery-background.jpg" alt="" />
      <p class="small-date">week {{ weekSelectedNumber }}</p>
      <h3>Recovery | {{ selectedWeekMonSunday }}</h3>
    </a>
  </div>
  <div v-else>
    <div class="empty-state">
      <img src="../img/emptyStateIllustration.svg" />
      <p>No Programmes, Please select a date above to view your programmes.</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_SELECTED_USER_DATA,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";

export default {
  name: "trainingPlanLinks",
  data() {
    return {
      loadingStuff: false,
      weekSelectedNumber: "",
      selectedWeekMonSunday: "",
      userData: "",
    };
  },
  props: {
    selectedUserData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  async created() {
    const now = moment().format("MM-DD-YYYY");
    this.weekSelectedNumber = moment(now).isoWeek();
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);
    this.selectedWeekMonSunday = localStorage.getItem(
      "mondaySunDates",
      this.selectedWeekMonSunday
    );
    const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );
    response === true ? this.setUser() : this.getUserById(clientId);
  },
  methods: {
    openTrainingPlanPage(userId, userType, planType) {
      if (userType === "User") {
        this.$store.dispatch(POPULATE_SELECTED_USER_DATA, userId);
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: 0, trainingPlanType: planType },
        });
      } else {
        this.$router.push({
          name: "trainingPlans",
          params: { trainingPlanUserId: userId, trainingPlanType: planType },
        });
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
      }
    },
  },
};
</script>
