<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div v-if="userData.name !== ''" class="uk-container uk-container-large">

      <h1>Help & support</h1>
      <div class="sessions-week-layout">

        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'contact-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'contact-tab')"
              href="javascript:;"
              >Contact</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'tutorials-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'tutorials-tab')"
              href="javascript:;"
              >Tutorials</a
            >
          </li>
        </ul>
        <div class="tabs-wrapper">
          <div
            class="current-week-tab documents-tab tab-content"
            v-if="selectedTab === 'contact-tab'"
          >

          <p>Contact support: <a href="mailTo:bigs@stewartpaxton.co.uk">email</a></p>
          </div>

          <div
            class="current-week-tab documents-tab tab-content"
            v-if="selectedTab === 'tutorials-tab'"
          >

          <div class="tutorials-wrapper">

            <h3>Player / parents tutorials</h3>

            <a href="./player-game-attendance.pdf" target="_blank"><b-icon icon="journals"></b-icon> Player game attendance</a>
            <a href="./player-submit-a-wellness-report.pdf" target="_blank"><b-icon icon="journals"></b-icon> Submitting a wellness report report</a>
            <a href="./player-Submitting-training-report.pdf" target="_blank"><b-icon icon="journals"></b-icon> Submitting a training report</a>
            <a href="./player-Submitting-Game-Performance-Report-Instructions.pdf" target="_blank"><b-icon icon="journals"></b-icon> Submitting a performancereport</a>

          </div>


          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import AppTemplate from "../layouts/App";
import UserHeader from "../../components/UserHeader";
import SpinnerLoader from "../../components/LoadingSpinning";
import VModal from "vue-js-modal";
import moment from "moment";
import wysiwyg from "vue-wysiwyg";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";

Vue.use(VModal);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    bold: false,
    italic: false,
    underline: false,
    justifyLeft: false,
    justifyCenter: false,
    justifyRight: false,
    headings: false,
    link: false,
    code: false,
    orderedList: false,
    unorderedList: false,
    image: false,
    table: false,
    removeFormat: false,
    separator: false,
  },
  forcePlainTextOnPaste: true,
});

export default {
  name: "Help",
  mixins: [removeDuplicatesMixin],
  components: { AppTemplate, SpinnerLoader },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      editMode: false,
      loadingStuff: false,
      selectedUserValue: "",
      weekSelectedNumber: "",
      sessionCompleted: false,
      weeklyDate: "",
      userHeaderData: {
        name: "",
        userType: "",
      },
      userData: {
        name: "",
        userType: "",
      },
      selectedTab: "tutorials-tab",
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData", "getWeekNumber"]),
  },
  created() {
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then((response) => {
      if (response === true) {
        this.setUser();
      } else {
        let clientId = JSON.parse(
          localStorage.getItem("the_w_selectedClientId")
        );
        this.getUserById(clientId).then((response) => {
          if (response === true) {
            this.selectedUserValue = clientId;
          }
        });
      }
    });
  },
  methods: {
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const response = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (response.data.length > 0) {
        this.userData = response.data[0];
        const userData = response.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
  },
};
</script>
<style scoped lang="scss">


.tutorials-wrapper {
  padding:1rem;

  a  {
    display: block;
    border: 1px solid #a2be8a;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    text-decoration: none;
    color:#5f893c;

    &:hover, &:focus {
      background: #e4f1d9;
      cursor: pointer;
    }
}
  }

.document-uploads-tab {
  padding:2rem;
}

.signed-documents-wrapper {
  display:block;

  .signed-documents {

    display:flex;
  flex-wrap:wrap;
  gap:1rem;

  }

}
.tabs-wrapper .tab-content {
  padding: 1rem;
}

.sorting-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;

  .filter-by {
    background: transparent;
  }

  .search {
    max-width: 20rem;

    input {
      padding: 0.5rem;
      font-size: 0.875rem;
      border-radius: 0;
      background: "white";
    }

    .input-group-text {
      padding: 0.5rem;
      border-radius: 0;
    }
  }
}

.access-pool {
  padding: 1rem;
  border: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  .has-access {
    background: #e3f0fb;
    padding: 0.5rem;
    display: block;
    border-radius: 10px;
    cursor: pointer;

    &:hover,
    &:focus {
      background: #f8e0e0;
    }
  }
}

.buttons-row {
  margin-top: 1rem;
  padding-top: 1rem;
}

.file-upload {
    border: 1px solid #dddddd;
    padding: 1rem;
    margin-bottom: 1rem;

}

.documents-tab {
  display: flex;
  flex-wrap: wrap;

  .document {
    max-width: 14rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(138, 138, 138, 0.3);
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #dcca0d;
    }

    h3 {
      margin-top: 1rem;
      font-size: 1rem;
      overflow: hidden;
    white-space: nowrap;
    }

    img {
      width: 100%;
    }
  }
}

.doc-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  svg {
    border: 1px solid #c3cdd5;
    padding: 0.3rem;
    font-size: 1.7rem;

    &:hover,
    &:focus {
      cursor: pointer;
      background: #eaeff3;
    }
  }
}
</style>
