<template>
  <div>
    <spinner-loader :loadingStuff="loadingStuff" />
    <app-template />
    <div v-if="userData.name !== ''" class="uk-container uk-container-large">
      <ul class="breadcrumb">
      <li><router-link :to="{ name: 'home' }"><b-icon icon="house-door" aria-hidden="true"></b-icon></router-link></li> &#8594;
      <li><router-link :to="{ name: 'ptHome' }">PT Dashboard</router-link></li> &#8594;
      <li>Training Plan</li>
    </ul>
      <template>
        <user-header 
        :userDataForHeader="userHeaderData"
        @weekSelectedChanged="weekSelectedChanged"
        :coachesUsersData="coachesUsersData"
        @selectedUseChanged="selectedUseChanged"
      />
      </template>
      <div class="sessions-week-layout">
        <ul class="tabs">
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'current-week-tab'
                  ? 'tab-link active'
                  : 'tab-link'
              "
              @click="tabChange('selectedTab', 'current-week-tab')"
              href="javascript:;"
              >This weeks training</a
            >
          </li>
          <li class="tab-item">
            <a
              :class="
                selectedTab === 'archive' ? 'tab-link active' : 'tab-link'
              "
              @click="tabChange('selectedTab', 'archive')"
              href="javascript:;"
              >Training library</a
            >
          </li>
        </ul>
        <div
          class="tabs-wrapper">
          <div
            class="current-week-tab training-plans tab-content"
            v-if="selectedTab === 'current-week-tab'">
            <training-plan-library
              :trainingTypeAndUserData="{
                trainingType: getRouteParams(),
                userId: $route.params.trainingPlanUserId,
                trainingData: 'currentWeek',
              }"
            />
          </div>
          <div class="archive training-plans tab-content" v-if="selectedTab === 'archive'">

            <training-plan-library
              :trainingTypeAndUserData="{
                trainingType: getRouteParams(),
                userId: $route.params.trainingPlanUserId,
                trainingData: 'library',
              }"
            />
          </div>
        </div>
      </div>

      <div>
</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CHECK_PROFILE_HAS_DATA,
  USER_POPULATE,
  POPULATE_COACHES_USER_DATA
} from "@/store/actions/user.js";
import { Users } from "@/services/user-service.js";
import { TrainingPlan } from "@/services/training-plan-service.js";
import AppTemplate from "../layouts/App";
import UserHeader from "../../components/UserHeader";
import SpinnerLoader from "../../components/LoadingSpinning";
import TrainingPlanLibrary from "../../components/trainingPlanLibrary";
import VModal from "vue-js-modal";
import moment from "moment";
import wysiwyg from "vue-wysiwyg";

Vue.use(VModal);
Vue.use(wysiwyg, {
  forcePlainTextOnPaste: true,
});

export default {
  name: "TrainingPlans",
  components: { AppTemplate, UserHeader, SpinnerLoader, TrainingPlanLibrary },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      editMode: false,
      imageFile: null,
      imageToBeRemoved: null,
      imageURL: "",
      image: {
        imageName: "",
        path: "",
        file: null
      },
      loadingStuff: false,
      trainingPlanHasData: false,
      selectedUserValue: "",
      weekSelectedNumber: "",
      sessionCompleted: false,
      weeklyDate: "",
      newSessionData: {
        userId: "",
        section: "",
        details: "",
        weekSelectedNumber: "",
        name: "",
        introText: "",
        notes: "",
        day: "",
        year: "",
        week: ""
      },
      userHeaderData: {
        name: "",
        userType: ""
      },
      userData: {
        name: "",
        userType: ""
      },
      coachesUsersData: [],
      trainingPlanArciveData: [],
      trainingPlanData: [],
      sessionDataModal: {},
      selectedTab: "current-week-tab",
      selectedSessionTab: "details",
      needsValidation: [],
      validationMessage: null,
      validationValues: {
        name: {
          state: false,
          text: 'Please add a name'
        },
        introText: {
          state: false,
          text: 'Please add session intro Text'
        },
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters(["getProfile", "getCoachesUsersData", "getWeekNumber"])
  },
  created() {
    this.$store.dispatch(CHECK_PROFILE_HAS_DATA).then(response => {
      if (response === true) {
        this.$route.params.trainingPlanUserId !== 0
          ? this.setheaderInfoForUser()
          : this.setHeaderUserData();

        this.setUser();
      } else {
        this.setSectionType();

        if (this.$route.params.trainingPlanUserId !== 0) { userHeaderClientId = this.$route.params.trainingPlanUserId; }
        let clientId = JSON.parse(localStorage.getItem("the_w_selectedClientId"));
        this.getUserByIdForHeader(userHeaderClientId);
          
        this.getUserById(clientId).then(response => {
          if (response === true) {
            if (
              this.userData.userType === "Admin" ||
              this.userData.userType === "Coach"
            ) {
              this.selectedUserValue = this.$route.params.trainingPlanUserId;
            } else {
              this.selectedUserValue = clientId;
            }
          }
        });
      }
    });
  },
  methods: {
    setheaderInfoForUser() {
      this.getUserByIdForHeader(this.$route.params.trainingPlanUserId);
    },
    getRouteParams() {
      return this.$route.params.trainingPlanType !== undefined
        ? this.$route.params.trainingPlanType
        : "All";
    },
    setSectionType() {
      switch(this.$route.name) {
        case 'trainingPlans':
          this.newSessionData.section = 'Training Plan';
          break;
      }
    },
    checkChangeVlaidation(value, nameOfElementToValidate) {
      if (value.length > 0) {
        this.validationValues[nameOfElementToValidate].state = false;
      }
    },
    setValidationMessage () {
      if (this.needsValidation.length === 0) {
        this.validationMessage = null;
        this.needsValidation = [];
      } else {
        this.validationMessage = 'Please fix the validation errors before you can proceed.';
        this.isLoading = false;
      }
    },
    reCheckValidation() {
      this.setValidationMessage();
      return this.needsValidation.length === 0;
    },
    populateValidationArray(validationData) {
      return validationData.map((validation) => {
        return validation === true;
      });
    },
    filterValidationResults(validationResults) {
      return validationResults.filter((result) => {
        return result === true;
      });
    },
    togglevalaidationErrors(name, validationState) {
      this.validationValues[name].state = validationState;
    },
    requiredValidation(value) {
      return (!value || value.length === 0);
    },
    setUpValidation (validationValue, validationName) {
      const validationstate = this.requiredValidation(validationValue);
      this.togglevalaidationErrors(validationName, validationstate);
      return validationstate;
    },
    validationCheck(event) {
      let validationData = [];
      this.needsValidation = [];
      validationData.push(this.setUpValidation(this.newSessionData.name, 'name'));
      validationData.push(this.setUpValidation(this.newSessionData.introText, 'introText'));
      let validationResults = this.populateValidationArray(validationData);
      this.needsValidation = this.filterValidationResults(validationResults);
      return this.reCheckValidation();
    },
    addEditProgrammeSubmit(e) {
      if (this.editMode === false) {
        if (this.validationCheck(e) === true) {
          this.savetrainingNewPlan();
        }
      } else {
         if (this.validationCheck(e) === true) {
           this.edittrainingPlan();
         }
      }
    },
    getTrainingTypeAndUserData() {
      if (
        this.userData.userType === "Admin" ||
        this.userData.userType === "Coach"
      ) {
          return {trainingType: this.$route.params.trainingPlanType, userId: this.$route.params.trainingPlanUserId}
        } else {
          this.selectedUserValue = this.userData.id;
          return {trainingType: this.$route.params.trainingPlanType, userId: this.userData.id}
        }
        return null;
    },
    removeFileFromUI() {
      this.imageURL = "",
      this.image = {
        imageName: "",
        path: "",
        file: null
      };
    },
    showModal(modelId) {
       this.$bvModal.show(modelId);
      },
      hideModal(modelId) {
        this.$bvModal.hide(modelId);
      },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    checkForImageFile(file, imageName) {
      return typeof file === "string" ? this[imageName] : file;
    },
    setImageToBeRemoved(imageFileName) {
      if (imageFileName === "imageFile") {
        this.imageToBeRemoved = this.imageURL;
      }
    },
    createObjectURLCheck(file, imageFileName, imageNumber) {
      if (file.type === "image/heic") {
        this.convertHeicToJpg(file, imageFileName, imageNumber);
      }
      "srcObject" in this
        ? (this.srcObject = file)
        : (this.image.path = URL.createObjectURL(file));
    },
    async convertHeicToJpg(file, imageFileName, imageNumber) {
      let base64 = URL.createObjectURL(file);
      fetch(base64)
        .then(res => res.blob())
        .then(blob => heic2any({ blob, toType: "image/jpeg" }))
        .then(conversionResult => {
          let base64 = URL.createObjectURL(conversionResult);
          this[imageNumber].path = base64;
          this[imageFileName] = new File([conversionResult], "newimage", {
            type: "image/jpeg"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    checkforDotAtTheStartOfUrl(p) {
      return p.startsWith(".") ? p.substring(1) : p;
    },
    checkTheimageForBlob(p, imageNumber) {
      if (p !== null) {
        var URL = this.checkforDotAtTheStartOfUrl(p);
      }
      switch (typeof URL) {
        case "string":
          URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
          return URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
        case "object":
          return this[imageNumber].path;
      }
    },
    getPath(p, imageNumber) {
      if (p === undefined) {
        return;
      }
      return this.checkTheimageForBlob(p, imageNumber);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.imageFile = event.target.result;
      };
    },
    imageChange(event, imageFileName, imageNumber, inputRef) {
      const file = event.currentTarget.files[0];
      this.createObjectURLCheck(file, imageFileName, imageNumber);
			this.image.file = file;
      this.image.imageName = file.name;

       var files = event.target.files || event.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    tabChange(tabValue, selectedTabValue) {
      this[tabValue] = selectedTabValue;
    },
    populateProgrammeDataWithSelectedUser() {
      this.newSessionData.userId = this.selectedUserValue.id;
    },
    newSessionObjectReset() {
      this.weeklyDate = "";
      this.newSessionData = {
        userId: "",
        section: "",
        details: "",
        weekSelectedNumber: "",
        name: "",
        introText: "",
        notes: "",
        day: "",
        year: "",
        week: ""
      };
      this.setSectionType();
    },
    openTrainingPlanPage() {
      this.$router.push({ name: "trainingPlans" });
    },
    calendarDateChanged(date) {
      this.weeklyDate = date;
    },
    selectedUseChanged(userId) {
      this.selectedUserValue = userId.selectedUser;
    },
    weekSelectedChanged(number) {
      this.weekSelectedNumber = number.weekSelectedNumber;
    },
    async getUserByIdForHeader(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID"
      };
      const responce = await Users.find(userData).catch(error => {
        console.log(error);
      });

      if (responce.data.length > 0) { this.userHeaderData = responce.data[0]; }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID"
      };
      const responce = await Users.find(userData).catch(error => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        console.log(this.userData);
        const userData = responce.data[0];
        this.$store.dispatch(USER_POPULATE, { userData });
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.checkForCoachesUsersData();
        }
        return true;
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    setHeaderUserData() {
      if (this.getProfile !== null) {
        this.userHeaderData = this.getProfile;
      }
    },
    makeTraingDataObject(actionName) {
      let data = this.newSessionData;
      data.complete = false;
      data.action = actionName;

      if (this.image.file !== null) {
         data.imageFile = this.imageFile;
         data.fileName = this.image.imageName;
      }
      return data;

    },
    actionsAfterFitnessSessionEditCreate() {
      this.hideModal("addEditProgramme");
      this.gettrainingPlansByUserIdAndWeek(this.selectedUserValue);
      this.loadingStuff = false;
      this.$bvToast.toast('Created successfully.', {
        title: 'Created',
        toaster: 'b-toaster-bottom-right',
        solid: true,
        appendToast: true,
         variant: 'success',
      });
    },
    async edittrainingPlan() {
      this.loadingStuff = true;
      const trainingPlanData = this.makeTraingDataObject("edittrainingPlan");
      const responce = await TrainingPlan.store(trainingPlanData).catch(
        error => {
          console.log(error);
        });

        if (responce === 'Training session updated') {
        this.actionsAfterFitnessSessionEditCreate();
        this.editMode = false;
      }
    },
    async getAllUsersForThisAdmin() {
      const userData = {
        coachId: this.userData.id,
        action: "getUserByCoachId"
      };
      const responce = await Users.find(userData).catch(error => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.coachesUsersData = responce.data;
        this.$store.dispatch(POPULATE_COACHES_USER_DATA, this.coachesUsersData);
        localStorage.setItem("coachesUsersData",JSON.stringify(this.coachesUsersData));
      }
    },
    checkForCoachesUsersData() {
      if (this.getCoachesUsersData.length > 0) {
        this.coachesUsersData = this.getCoachesUsersData;
      } else {
        if (
          this.userData.userType === "Admin" ||
          this.userData.userType === "Coach"
        ) {
          this.getAllUsersForThisAdmin();
        }
      }
    },
    setTheWeekNumber(date) {
      this.weekSelectedNumber = moment(date).isoWeek();
      this.newSessionData.week = moment(date).isoWeek();
      this.newSessionData.day = moment(date, "DD MMM YYYY").day();
      this.newSessionData.year = moment(date, "DD MMM YYYY").year();
    },
    makeToast(title, message, variant) {
          this.$bvToast.toast(message, {
          title: title,
          variant: variant,
          solid: true
        })
      }
  }
};
</script>
