<template>
  <div class="space">
    <button @click="addPlannerEvent()" class="green-button add-new-programme">
      Add Event
    </button>
    <div class="cal-buttons-wrapper">
      <a
        v-if="dayViewSelected"
        class="cal-buttons"
        href="javascript:;"
        @click="changeWeek(-1)"
        >Previous</a
      >
      <a
        v-if="dayViewSelected"
        class="cal-buttons"
        href="javascript:;"
        @click="changeWeek(1)"
        >Next</a
      >

      <a
        v-if="dayViewSelected"
        class="cal-buttons"
        href="javascript:;"
        @click="getStartOfTheWeek()"
        >Today</a
      >

      <a
        :class="dayViewSelected ? 'selected' : ''"
        class="cal-buttons"
        href="javascript:;"
        @click="switchView('Day')"
        >Day</a
      >

      <a
        v-if="!dayViewSelected"
        class="cal-buttons"
        href="javascript:;"
        @click="changeWeek(-7)"
        >Previous Week</a
      >
      <a
        v-if="!dayViewSelected"
        class="cal-buttons"
        href="javascript:;"
        @click="changeWeek(7)"
        >Next Week</a
      >

      <a
        :class="!dayViewSelected ? 'selected' : ''"
        class="cal-buttons"
        href="javascript:;"
        @click="switchView('Week')"
        >Week</a
      >

      <datepicker
        name="weeklyDate"
        id="weeklyDate"
        @selected="setTheWeekNumber"
        :highlighted="state.highlighted"
        @focusin.native="datePickerrOpened"
      />
    </div>
    <DayPilotCalendar id="dp" :config="config" ref="calendar" />

    <b-modal
      id="addEditProgramme"
      size="lg"
      :title="selectedGame.name"
      :hide-footer="Boolean(true)"
    >
    </b-modal>

    <b-modal
      id="viewGameDetails"
      size="lg"
      :title="selectedGame.teamName"
      :hide-footer="Boolean(true)"
    >
      <h2>
        {{ selectedGame.gameType }}
        <span v-if="selectedGame.gameType !== 'Training'"
          >V {{ selectedGame.opposition }}</span
        > - {{setPitchZoneText(selectedGame)}}
      </h2>
      <p>
        <span v-if="selectedGame.gameType !== 'Training'"
          ><b-icon icon="calendar2-day" aria-hidden="true"></b-icon> Kick
          off:</span
        >
        <b-icon
          v-if="selectedGame.gameType === 'Training'"
          icon="alarm"
          aria-hidden="true"
        ></b-icon>
        {{ getDayAndDate(selectedGame.matchDate) }}
        <span v-if="selectedGame.gameType !== 'Training'"> at </span
        >{{ formatISOStringDateAndTime(selectedGame.kickOffTime) }}
        <span v-if="selectedGame.gameType === 'Training'"
          >- {{ formatISOStringDateAndTime(selectedGame.endTime) }}</span
        ><br />
        <span v-if="selectedGame.gameType !== 'Training'"
          ><b-icon icon="alarm" aria-hidden="true"></b-icon> Report for:
          {{ formatISOStringDateAndTime(selectedGame.meetTime) }}</span
        >
      </p>

      <span class="venue-text"
        ><b-icon icon="map" aria-hidden="true"></b-icon> Venue:
        {{ selectedGame.address }} {{ selectedGame.postCode }}</span
      >
      <p class="padding-left">
        {{ selectedGame.locationName }} -
        <a
          class="map-link"
          v-if="selectedGame.postCode !== ''"
          :href="`https://maps.google.fr/maps?q=${selectedGame.postCode}`"
          target="_blank"
          ><b-icon icon="pin-map" aria-hidden="true"></b-icon> Map</a
        >
      </p>
      <p>
        <b-icon icon="grid3x3 " aria-hidden="true"></b-icon> Pitch type:
        {{ selectedGame.pitchType }}
      </p>
      <p v-if="selectedGame.gameType !== 'Training'">
        <b-icon icon="person" aria-hidden="true"></b-icon> Strip:
        {{ selectedGame.stripType }}
      </p>
      <p v-if="selectedGame.notes != ''">
        <b-icon icon="chat-right-text" aria-hidden="true"></b-icon>
        {{ selectedGame.notes }}
      </p>

      <div
        v-if="checkUserIsAttendingGame() === false"
        class="player-game-responce"
      >
        <b-button 
        v-if="userData.userType !== 'Admin' && userData.userType !== 'Coach'"
        @click="gameResponce('Yes')" variant="outline-success"
          ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon> Yes
          going</b-button
        >
        <b-button 
        v-if="userData.userType !== 'Admin' && userData.userType !== 'Coach'"
        @click="gameResponce('No')" variant="outline-danger"
          ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon> No can't
          make it</b-button
        >
      </div>
      <div v-else class="player-game-responce">
        <b-button disabled variant="success"
          ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon>
          Attending</b-button
        >
        <b-button 
        v-if="userData.userType !== 'Admin' && userData.userType !== 'Coach'"
        @click="gameResponce('No')" variant="outline-danger"
          ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon> No can't
          make it</b-button
        >
        <p class="attending-game">
          <b-icon icon="hand-thumbs-up" aria-hidden="true"> </b-icon> You are
          currenlty attending this
          <span v-if="selectedGame.gameType === 'Training'">Session</span>
          <span v-else>Game</span>
        </p>
      </div>

      <div v-if="userData.userType === 'Admin'" class="player-game-numbers">
        <h3>Responses</h3>
        <ul>
          <li v-for="(attendee, index) in selectedGameAttendees" :key="index">
            <p>
              {{ attendee.name }}
              <span class="going" v-if="attendee.going == '1'"
                ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon>
                going</span
              >
              <span class="not-going" v-else-if="attendee.notGoing == '1'"
                ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon> No
                can't make it</span
              >
              <span class="no-response" v-else>No Response</span>
            </p>
          </li>
        </ul>
      </div>
    </b-modal>

    <b-modal
      id="addEvent"
      size="lg"
      title="Add Event"
      :hide-footer="Boolean(true)"
    >
      <div class="form">
        <div class="form__item">
          <label class="form__label" for="selectEventType"
            >Select event type</label
          >
          <select v-model="plannerEventData.eventType" id="selectEventType">
            <option disabled value="">Please select event type</option>
            <option value="Normal">Normal</option>
            <option value="Training plan">Training plan</option>
          </select>
        </div>

        <template v-if="plannerEventData.eventType !== ''">
          <div
            v-if="plannerEventData.eventType === 'Normal'"
            class="form__item"
          >
            <label class="form__label" for="name">Event name</label>
            <input
              class="form__input"
              type="text"
              v-model="plannerEventData.name"
              id="name"
            />
          </div>

          <div
            v-if="plannerEventData.eventType === 'Training plan'"
            class="form__item"
          >
            <label
              v-b-tooltip.hover
              title="The training plan id is displayed on each plan, copy it and paste it into the Training plan id box."
              class="form__label"
              for="name"
              >Training plan id
              <b-icon
                icon="exclamation-circle"
                style="width: 24px; height: 24px"
              ></b-icon
            ></label>
            <div class="wrap">
              <input
                class="form__input"
                type="number"
                v-model="plannerEventData.trainingPlanId"
                id="trainingPlanId"
              />
              <button @click="findTrainingPlanById">Find Training plan</button>
            </div>
            <div></div>
            <div class="training-plan-display">
              <p>Selected training plan</p>
              <p
                v-if="trainingPlanSelectedData.name !== undefined"
                class="selected-training-plan-box"
              >
                {{ trainingPlanSelectedData.name }} - id
                {{ trainingPlanSelectedData.id }}
                <img
                  v-if="trainingPlanSelectedData.imageURL !== ''"
                  :src="getImgUrl(trainingPlanSelectedData.imageURL)"
                  alt=""
                />
                <img
                  v-else
                  :src="getImgUrl('/test-image.jpg')"
                  alt=""
                  class="session-image"
                />
              </p>
              <p v-else>No training plan</p>
            </div>
          </div>

          <div class="form__item">
            <label class="form__label" for="matchDate">Date</label>
            <datepicker
              wrapper-class="fullscreen-when-on-mobile"
              v-model="plannerEventData.date"
              name="matchDate"
              id="matchDate"
              :open-date="new Date()"
            />
          </div>

          <div class="form__item">
            <label class="form__label" for="kickOff">
              <span>Start time</span>
              time</label
            >
            <input
              id="kickOff"
              type="time"
              min="06:00"
              max="23:00"
              v-model="plannerEventData.start"
              name="kickOff"
            />
          </div>
          <div>
            <div class="form__item">
              <label class="form__label" for="endTime">End time</label>
              <input
                id="endTime"
                type="time"
                min="06:00"
                max="23:00"
                v-model="plannerEventData.end"
                name="endTime"
              />
            </div>
          </div>
        </template>

        <div class="form__item">
          <button @click="addDailyPlannerCall()" class="green-button">
            Add event
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="sessionModal"
      size="lg"
      :title="trainingPlanSelectedData.name"
      :hide-footer="Boolean(true)"
    >
      <div class="tabs-wrapper">
        <div
          class="tab-content no-flex"
          v-if="selectedSessionTab === 'details'"
        >
          <div
            v-if="trainingPlanSelectedData.imageURL !== ''"
            class="large-training-image-wrapper"
          >
            <img
              class="large-training-image"
              :src="getImgUrl(trainingPlanSelectedData.imageURL)"
              alt=""
            />
          </div>

          <div class="training-details-content">
            <h4>Description</h4>
            <p>{{ trainingPlanSelectedData.introText }}</p>
            <div v-html="trainingPlanSelectedData.details"></div>

            <div class="notes">
              <h4>Notes</h4>
              <p>{{ trainingPlanSelectedData.notes }}</p>
            </div>
          </div>
        </div>
        <div class="tab-content" v-if="selectedSessionTab === 'comments'"></div>
      </div>
    </b-modal>

    <b-modal
      id="sessionCustomModal"
      size="lg"
      :title="plannerEventData.name"
      :hide-footer="Boolean(true)"
    >
      <p>{{ plannerEventData.name }}</p>
      <div v-if="plannerEventData.name === 'Bed time - Game day tomorrow '">
        <h3>A good night sleep</h3>
        <p>
          A professional football player needs a rested body to perform at its
          best. For example, it is wise to go to bed on time the day before a
          football match.
        </p>
        <img
          style="width: 100%; margin-bottom: 2rem"
          src="./img/sleep-tips.jpg"
          alt=""
        />

        <h3>Pack your kit bag</h3>
        <p>
          Before going to bed, ensure that your kit bag is packed with your:
        </p>

        <ul>
          <li>Boots</li>
          <li>Shin guards</li>
          <li>Towel</li>
          <li>Rain jacket</li>
          <li>Toiletries</li>
          <li>Water bottle</li>
          <li>Isotonic drink</li>
          <li>Other items at Coach’s request</li>
        </ul>
      </div>

      <div v-if="plannerEventData.name === 'Breakfast - game day'">
        <h3>What to eat before games</h3>
        <p>
          Starting matches with proper nutrition is crucial. While each player's
          needs may vary, it is generally recommended to consume a pre-game meal
          approximately 3 to 4 hours before kick-off. This meal should consist
          of carbohydrates to provide fuel for the game, fluids to ensure
          adequate hydration, and a modest amount of protein to curb hunger
          during the match. By following these guidelines, players can optimize
          their performance and maintain their energy levels throughout the
          game.
        </p>

        <p>
          Some suitable pre-game meal ideas from the Nutrition training library:
        </p>

        <div class="tp-content-modal-wrapper">
          <div class="tp-content-modal">
            <div class="training-session-entry">
              <div class="session-content">
                <div class="session-image-wrapper">
                  <img
                    src="/images/test-22264a530dc355bc.jpeg"
                    alt=""
                    class="session-image"
                  />
                </div>
                <div class="session-data">
                  <h3>PROTEIN PANCAKES</h3>
                  <p class="training-type-text">Nutrition - id: 112</p>
                  <p class="week-text">Week number: 27 | 2023</p>
                  <p>
                    breakfast recipe that will fuel your training and help
                    manage your weight.
                  </p>
                </div>
              </div>
            </div>

            <div class="training-session-entry">
              <div class="session-content">
                <div class="session-image-wrapper">
                  <img
                    src="/images/over-night-oats64a531dc15b82.jpeg"
                    alt=""
                    class="session-image"
                  />
                </div>
                <div class="session-data">
                  <h3>OVERNIGHT OATS AND PEANUT BUTTER</h3>
                  <p class="training-type-text">Nutrition - id: 113</p>
                  <p class="week-text">Week number: 27 | 2023</p>
                  <p>
                    Porridge – known as the breakfast of champions – leaves you
                    feeling full and with additional muscle-nourishing protein
                    from the peanut butter provides a good supply of
                    long-lasting energy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>Other ideas:</p>

        <ul>
          <li>Wrap or sandwich with chicken and salad</li>
          <li>Bowl of muesli with yoghurt and berries</li>
          <li>Pasta with beef mince in tomato-based sauce</li>
          <li>Pumpkin soup served with bread rolls</li>
          <li>Chicken stir-fry with rice or quinoa</li>
        </ul>

        <p>
          Many players will also have an additional small snack 1-2 hours prior
          to the game. This is often something light that is rich in
          carbohydrate but relatively low in fat and fibre so it is easy to
          digest.
        </p>

        <p>Some suitable pre-game snack ideas include:</p>

        <ul>
          <li>Yoghurt with fruit salad</li>
          <li>Banana and a handful of almonds</li>
          <li>Peanut butter on rice cakes</li>
          <li>Toast with vegemite and cheese</li>
        </ul>

        <p>
          If solids don’t sit well before a game, or players are very nervous, a
          liquid source of protein and carbohydrate such as a fruit smoothie can
          be a good option.
        </p>
      </div>

      <div v-if="plannerEventData.name === 'Post-match recovery'">
        <h3>Post-match recovery advice</h3>
        <p>
          Recovery meals and snacks should contain carbohydrate (fuel), some
          protein (for muscle repair and development) and plenty of fluids and
          electrolytes to replace sweat losses.
        </p>

        <p>
          A recovery meal or snack should be consumed soon after exercise
          period, remembering that recovery nutrition extends well beyond the
          initial hours post-game, particularly when the next training session
          or game is the next day. Fluids (mainly water) should also be
          consumed, based on estimated losses. Some recovery food suggestions
          include:
        </p>

        <ul>
          <li>Chicken, avocado and salad sandwich</li>
          <li>Dairy-based fruit smoothie</li>
          <li>Yoghurt + muesli with nuts and seeds</li>
          <li>Burritos with beef, cheese, avocado and salad</li>
        </ul>
      </div>

      <div v-if="plannerEventData.name === 'Submit game report'">
        <h3>
          Please submit your training report for League game V Baillieston
          Thistle 2010s
        </h3>
        <form class="form">
          <div class="form__item">
            <label class="form__label" for="coachSelect">Select game</label>
            <select id="coachSelect">
              <option disabled value="">Please select a game</option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="goals"
              >What position did you play?</label
            >
            <select id="coachSelect">
              <option disabled value="">Please select a position</option>
              <option value="Goalkeeper">Goalkeeper</option>
              <option value="Right Full-back (Wingback)">
                Right Full-back (Wingback)
              </option>
              <option value="Left Full-back (Wingback)">
                Left Full-back (Wingback)
              </option>
              <option value="Center-back left">Center-back left</option>
              <option value="Center-back right">Center-back right</option>
              <option value="Defensive Midfielder">Defensive Midfielder</option>
              <option value="Centre midfield">Centre midfield</option>
              <option value="Centre Defensive Midfield">Centre Defensive Midfield</option>
              <option value="Right Midfielder (Winger)">
                Right Midfielder (Winger)
              </option>
              <option value="Left Midfielder (Winger)">
                Left Midfielder (Winger)
              </option>
              <option value="Attacking Midfielder">Attacking Midfielder</option>
              <option value="Center Forward (Striker) left">
                Center Forward (Striker) left
              </option>
              <option value="Center Forward (Striker) right">
                Center Forward (Striker) right
              </option>
              <option value="I played more than one position">
                I played more than one position
              </option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="goals"
              >How long did you play for?</label
            >
            <select id="coachSelect">
              <option value="70">Full game</option>
              <option value="60">Almost a Full game</option>
              <option value="35">One half</option>
              <option value="20">Less than one half</option>
              <option value="10">Last 10mins</option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="goals">Did you get a card?</label>
            <select id="coachSelect">
              <option value="no">No</option>
              <option value="Yellow">Yellow</option>
              <option value="two Yellows (red)">Two yellows (red)</option>
              <option value="red">red</option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="goals"
              >How did you think you played?</label
            >
            <select id="coachSelect">
              <option value="Amazing">Amazing</option>
              <option value="Good">Good</option>
              <option value="Ok">Ok</option>
              <option value="Not so good">Not so good</option>
              <option value="Could play better">Could play better</option>
            </select>
          </div>

          <div class="form__item">
            <label class="form__label" for="goals">How many Assists?</label>
            <input class="form__input" type="number" id="assists" />
          </div>

          <div class="form__item">
            <label class="form__label" for="goals"
              >How many goals did you score?</label
            >
            <input class="form__input" type="number" id="goals" />
          </div>
          <b-button type="submit" size="lg" variant="outline-success"
            ><b-icon icon="file-text" aria-hidden="true"></b-icon> Submit game
            performance report</b-button
          >
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-vue";
import { CHECK_PROFILE_HAS_DATA } from "@/store/actions/user.js";
import { Games } from "@/services/games-service.js";
import { Users } from "@/services/user-service.js";
import { TrainingPlan } from "@/services/training-plan-service.js";
import { DailyPlanner } from "@/services/daily-planner-servce.js";
import Datepicker from "vuejs-datepicker";
import extendSeasonEndDateToFullYearMixin from "@/mixin/extendSeasonEndDateToFullYear.js";
import setPitchZoneTextMixin from "@/mixin/pitchZoneText.js";
import moment from "moment";

export default {
  name: "DailyPlanner",
  mixins: [extendSeasonEndDateToFullYearMixin, setPitchZoneTextMixin],
  data: function () {
    return {
      selectedSessionTab: "details",
      trainingPlanSelectedData: {
        date: new Date(),
        eventType: "",
      },
      plannerEventData: {},
      dayViewSelected: true,
      state: {
        highlighted: {
          from: new Date("2023, 05, 25"),
          to: new Date("2023, 05, 30"),
          dates: [
            new Date("2023, 05, 16"),
            new Date("2023, 05, 17"),
            new Date("2023, 06, 18"),
          ],
        },
      },
      userData: {
        name: "",
        userType: "",
      },
      selectedGameAttendees: [],
      selectedGame: {},
      teamGamesData: [],
      events: [],
      navigatorConfig: {
        showMonths: 1,
        skipMonths: 1,
        selectMode: "Day",
        startDate: DayPilot.Date.today(),
        selectionDay: DayPilot.Date.today(),
        onTimeRangeSelected: (args) => {
          this.config.startDate = args.day;
        },
      },
      config: {
        viewType: "Day",
        startDate: DayPilot.Date.today(),
        durationBarVisible: true,
        timeRangeSelectedHandling: "Enabled",
        onTimeRangeSelected: async (args) => {
          // const modal = await DayPilot.Modal.prompt("Create a new event:", "Event 1");
          const dp = args.control;
          dp.clearSelection();
          if (modal.canceled) {
            return;
          }
          dp.events.add({
            start: args.start,
            end: args.end,
            id: DayPilot.guid(),
            text: modal.result,
          });
        },
        eventDeleteHandling: "Disabled",
        onEventMoved: () => {
          console.log("Event moved");
        },
        onEventResized: () => {
          console.log("Event resized");
        },
        onEventClicked: (args) => {
          if (args.e.data.training_plan_id === undefined) {
            this.showGameDetails(args.e.data);
          }
          if (
            args.e.data.training_plan_id !== undefined &&
            args.e.data.training_plan_id !== null
          ) {
            this.showtrainingPlanData(args.e.data.training_plan_id);
          }
          if (args.e.data.training_plan_id === null) {
            this.openCustomDailyPlannerModel(args.e.data);
          }
        },
        onBeforeEventRender: (args) => {},
      },
    };
  },
  props: {
    selectedUserData: {
      type: Object,
      default: null,
    },
    selectedTeamId: {
      type: [String, Number],
      default: null,
    },
  },
  components: {
    DayPilotCalendar,
    Datepicker,
  },
  computed: {
    ...mapGetters(["getProfile"]),
    calendar() {
      return this.$refs.calendar.control;
    },
  },
  async created() {
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();
      this.getSelectedTeamsEvents(
        moment().subtract(1, "months").format("YYYY-MM-DD"),
        moment().add(2, "months").format("YYYY-MM-DD")
      );
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );
      this.getUserById(clientId);
    }
  },
  methods: {
    openCustomDailyPlannerModel(data) {
      this.plannerEventData = data;
      this.$bvModal.show("sessionCustomModal");
    },
    async showtrainingPlanData(data) {
      this.plannerEventData.trainingPlanId = data;
      await this.findTrainingPlanById();
      this.$bvModal.show("sessionModal");
    },
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    getUserId() {
      return this.selectedUserData.id || this.userData.id;
    },
    async findTrainingPlanById() {
      const userData = {
        id: this.plannerEventData.trainingPlanId,
        action: "getTrainingPlanById",
      };
      const responce = await TrainingPlan.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.trainingPlanSelectedData = responce.data;
      } else {
        this.trainingPlanSelectedData = {
          eventType: "",
        };
      }
    },
    addPlannerEvent() {
      this.plannerEventData = {};
      this.$bvModal.show("addEvent");
    },
    getStartOfTheWeek() {
      const today = new Date();
      const dateData = new Date(
        today.setDate(today.getDate() - today.getDay())
      );

      this.calendar.startDate = dateData.toISOString().slice(0, 10);
      this.calendar.update();
    },
    changeWeek(amout) {
      this.calendar.startDate = this.calendar.startDate.addDays(amout);
      this.calendar.update();
    },
    switchView(type) {
      this.dayViewSelected = type === "Day";
      this.calendar.viewType = type;
      this.calendar.update();
    },
    highlightDatePickerWeek() {
      let startOfWeek = null;
      let endOfWeek = null;
      if (this.calendar.startDate.value !== undefined) {
        startOfWeek = moment(
          this.calendar.startDate.firstDayOfWeek().value,
          "YYYY-MM-DDT00:00:00"
        );
        endOfWeek = moment(
          this.calendar.startDate.firstDayOfWeek().addDays(7).value,
          "YYYY-MM-DDT00:00:00"
        );
      } else {
        startOfWeek = moment(
          moment(this.calendar.startDate).startOf("week"),
          "YYYY-MM-DDT00:00:00"
        );
        endOfWeek = moment(
          moment(this.calendar.startDate).endOf("week"),
          "YYYY-MM-DDT00:00:00"
        );
      }

      this.state.highlighted.from = moment(startOfWeek);
      this.state.highlighted.to = moment(endOfWeek);
      this.state.highlighted.dates = [
        new Date("2023-05-10"),
        new Date("2023-05-11"),
        new Date("2023-05-12"),
      ];
    },
    datePickerrOpened() {
      this.highlightDatePickerWeek();
    },
    setTheWeekNumber(date) {
      this.calendar.startDate = moment(date).format("YYYY-MM-DDT00:00:00");
      this.highlightDatePickerWeek();
      this.calendar.update();
    },
    setDailyPlannerDates() {
      const { date, start, end, eventType } = this.plannerEventData;

      this.plannerEventData.start = this.changeTimeToTimeAndDate(
        start,
        this.formatMatchDate(date)
      );
      this.plannerEventData.end = this.changeTimeToTimeAndDate(
        end,
        this.formatMatchDate(date)
      );
      this.plannerEventData.date = this.formatMatchDate(date);

      if (eventType === "Training plan") {
        this.plannerEventData.name = this.trainingPlanSelectedData.name;
      }
    },
    resetDailyPlannerData() {
      this.trainingPlanSelectedData = {
        date: new Date(),
        eventType: "",
      };
      this.plannerEventData = {};
    },
    async addDailyPlannerCall() {
      this.setDailyPlannerDates();
      let userIdValue = this.getUserId();
      const userData = {
        ...this.plannerEventData,
        user_id: userIdValue,
        action: "addDailyPlannerEvent",
      };
      const responce = await DailyPlanner.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data) {
        this.$bvModal.hide("addEvent");
        this.resetDailyPlannerData();
        this.getSelectedTeamsEvents();
        this.$bvToast.toast("Daily planner event added", {
          title: "success",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
      }
    },
    async getSelectedTraningPlansFromDailyPlanner() {
      let userIdValue = this.getUserId();

      const userData = {
        user_id: userIdValue,
        action: "getDailyPlannerEvents",
      };
      const responce = await DailyPlanner.find(userData).catch((error) => {
        console.log(error);
      });

      return responce.data.length > 0 ? responce.data : false;
    },
    async getAllAttendeesForAGame() {
      const userData = {
        userId: this.userData.id,
        kickOffTime: this.selectedGame.kickOffTime,
        teamId: this.selectedGame.teamId,
        action: "getAllAttendeesForAGame",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });
      this.selectedGameAttendees = responce.data;
    },
    checkUserIsAttendingGame() {
      let responce = false;
      if (this.selectedGameAttendees.length > 0) {
        this.selectedGameAttendees.filter((game) => {
          if (String(game.user_id) === String(this.userData.id)) {
            switch (game.going) {
              case "1":
                responce = true;
                break;
              case "0":
                responce = false;
                break;
            }
          }
        });
      }
      return responce;
    },
    changeTimeToTimeAndDate(timeString, date) {
      return `${date}T${timeString}:00.000Z`;
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    getDayAndDate(date) {
      return moment(date).format("dddd, Do");
    },
    showGameDetails(data) {
      this.selectedGame = data;
      this.getAllAttendeesForAGame();
      this.$bvModal.show("viewGameDetails");
    },
    addGameTypeColours(event) {
      switch (event.gameType) {
        case "League game":
          event.backColor = "#b3f4cb";
          event.borderColor = "#b3f4cb";
          event.barBackColor = "#6bca8e";
          return event;
        case "Training":
          event.backColor = "#b3dcf4";
          event.borderColor = "#b3dcf4";
          event.barBackColor = "#6bca8e";
          return event;
        case "Cup game":
          event.backColor = "#fdc400";
          event.borderColor = "#1155cc";
          event.barBackColor = "#6d9ae8";
          return event;
        default:
          event.backColor = "#d1f4b3";
          event.borderColor = "#d1f4b3";
          event.barBackColor = "#6d9ae8";
          return event;
      }
    },
    addeventValues(data) {
      return data.map((event) => {
        return {
          ...event,
          text: event.name,
          backColor: "#d1f4b3",
          borderColor: "#d1f4b3",
          barBackColor: "#6bca8e",
        };
      });
    },
    addEventBoxInformation(eventWithClours) {
      return eventWithClours.gameType === "Training"
        ? `${eventWithClours.gameType} - ${eventWithClours.teamName}`
        : `${eventWithClours.gameType} - ${eventWithClours.teamName} v ${eventWithClours.opposition}`;
    },
    addCalanderValues(data) {
      return data.map((event) => {
        if (event.endTime === "") {
          let date = new Date(event.kickOffTime);
          date.setHours(date.getHours() + 2);
          event.endTime = date.toISOString();
        }
        if (event.pitch_id !== "" && event.pitch_id !== "0") {
          event.game_id = event.id;
          event.resource = event.pitch_id;
        }

        let eventWithClours = this.addGameTypeColours(event);

        return {
          ...eventWithClours,
          id: Number(eventWithClours.id),
          start: eventWithClours.kickOffTime,
          end: eventWithClours.endTime,
          text: this.addEventBoxInformation(eventWithClours),
          backColor: eventWithClours.backColor,
          borderColor: eventWithClours.borderColor,
          barBackColor: eventWithClours.barBackColor,
        };
      });
    },
    setUser() {
      this.userData = this.getProfile || null;
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        this.getSelectedTeamsEvents();
      }
    },
    async getTrainingPlansById() {},
    async getSelectedTeamsEvents() {
      const data = {
        action: "getAllGamesEventsById",
        teamId: this.selectedTeamId,
        gameFromDate: localStorage.getItem("currentPlayingseasonStartDate"),
        gameToDate: this.extendSeasonEndDateToFullYear(),
      };
      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.events = this.addCalanderValues(responce.data);
        // this.loadEvents();
      }

      const plannerData = await this.getSelectedTraningPlansFromDailyPlanner();

      if (plannerData !== false) {
          const trainingPlanEventsData = this.addeventValues(plannerData);
          this.events = this.events.concat(trainingPlanEventsData);
        }
        this.loadReservations();
    },
    loadReservations() {
      const events = this.events;
      if (events.length > 0) {
        this.calendar.update({ events });
      }
    },
  },
  mounted() {
    //this.loadEvents();
  },
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
}

.left {
  margin-right: 10px;
}

.content {
  flex-grow: 1;
}

.calendar_default_event_inner {
  background: #2e78d6;
  color: white;
  border-radius: 5px;
  opacity: 0.9;
}

.training-plan-display {
  width: 100%;
  display: block;
  border: 1px solid #dddddd;
  padding: 1rem;
  margin: 1rem 0;

  .selected-training-plan-box {
    border: 1px solid #06569e;
    padding: 1rem;
  }
}

.tp-content-modal-wrapper {
  width: 100%;
  .tp-content-modal {
    display: flex;
    flex-wrap: wrap;

    .training-session-entry {
      background: white;
      padding: 1rem;
      border-radius: 4px;
      cursor: pointer;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.05);
      border: 1px solid transparent;
      width: 74%;
      margin-bottom: 2rem;

      .session-image-wrapper {
        background: #f2f5f7;

        img {
          display: block;
          margin: 0 auto 1rem auto;
        }
      }
    }
  }
}
</style>
