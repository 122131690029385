<template>
  <div class="sortBy">
    <b-form-select @change="onSortChange($event)" class="mb-3">
      <b-form-select-option value="Ascending"
        >Sort by Newest</b-form-select-option
      >
      <b-form-select-option value="Descending"
        >Sort by Oldest</b-form-select-option
      >
    </b-form-select>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    arrayDataForSorting: {
      type: Array,
      default: null,
    },
    sortingByValue: {
      type: String,
      default: null,
    },
  },
  methods: {
    onSortChange(value) {
      value === "Ascending"
        ? this.sortArrayByAscending()
        : this.sortArrayByDescending();
    },
    sortArrayByAscending() {
      this.setDefaultIfNull();
      if (
        this.arrayDataForSorting !== null &&
        this.arrayDataForSorting.length > 0
      ) {
        let arrayToReturn = this.arrayDataForSorting.sort(
          (b, a) =>
            new Date(b[this.sortingByValue]) - new Date(a[this.sortingByValue])
        );
        this.callSortedData(arrayToReturn);
      } else {
        console.log('no data man');
      }
    },
    sortArrayByDescending() {
      this.setDefaultIfNull();
      if (
        this.arrayDataForSorting !== null &&
        this.arrayDataForSorting.length > 0
      ) {
      let arrayToReturn = this.arrayDataForSorting.sort(
        (a, b) =>
          new Date(b[this.sortingByValue]) - new Date(a[this.sortingByValue])
      );
      this.callSortedData(arrayToReturn);
    } else {
        console.log('no data man');
      }
    },
    setDefaultIfNull() {
      if (this.sortingByValue === null) {
        this.sortingByValue = "created_date";
      }
    },
    callSortedData(arrayToReturn) {
      this.$emit("sortedDataCall", arrayToReturn);
    },
  },
};
</script>
