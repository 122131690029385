<template>
  <div>

    <div class="current-week-tab tab-content">

    <div class="tabs-wrapper">

      <button
        @click="addAPitch()"
        v-if="userData.userType === 'Admin'"
        class="green-button add-new-programme"
      >
        Add a Pitch
      </button>

      <b-button
        @click="editAPitch()"
        v-if="userData.userType === 'Admin'"
        class="edit-pitch-button"
        variant="outline-warning"
      >
        Edit a Pitch
      </b-button>
      <div class="cal-buttons-wrapper">
        <a class="cal-buttons" href="javascript:;" @click="changeWeek(-1)"
          >Previous</a
        >
        <a class="cal-buttons" href="javascript:;" @click="changeWeek(1)">Next</a>

        <!--<a class="cal-buttons" href="javascript:;" @click="switchToDayView()"
          >Day</a
        >
        <a class="cal-buttons" href="javascript:;" @click="switchToWeekView()"
          >Week</a
        >-->

        <a class="cal-buttons" href="javascript:;" @click="getStartOfTheWeek()"
          >Today</a
        >

        <b-dropdown id=teams_filter_button variant="transparent"  text="Split Link" checkbox-menu allow-focus right="right">
        <template #button-content>
            <b-button
                    variant="outline-success filter-small"
                  >
                    <b-icon icon="filter" aria-hidden="true"></b-icon>
                    Filter</b-button
                  >
          </template>

          <div>
                <input
                  @change="selectedAllTeamsToggle($event)"
                  type="checkbox"
                  id="selectedAllTeamsToggle"
                  name="selectedAllTeamsToggle"
                  :checked="allselectedTeamsToggle"
                />
                <label for="selectedAllTeamsToggle">Select all</label
                ><br />
          </div>

          <div v-for="team in clubTeams" :key="team.id">
                <input
                  @change="changeSelectedTeam"
                  type="checkbox"
                  :id="team.id"
                  :name="team.id"
                  :value="team.id"
                  v-model="listOfSelectedTeamIds"
                />
                <label :for="team.id">{{ team.teamName }}</label
                ><br />
              </div>
      </b-dropdown>
        </div>
      <section class="scheduler-wrapper">


      <DayPilotScheduler id="dp" :config="config" ref="scheduler" />

      </section>

    </div>

    <b-modal
      id="addAPitch"
      size="lg"
      title="Add a Pitch"
      :hide-footer="Boolean(true)"
    >
      <div class="form">
        <div class="form__item">
          <label class="form__label" for="name">Pitch name</label>
          <input
            class="form__input"
            type="text"
            v-model="pitchData.name"
            id="name"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="selectpitchSize"
            >Select Pitch size</label
          >
          <select v-model="pitchData.pitchSize" id="selectpitchSize">
            <option disabled value="">Please select a pitch size</option>
            <option value="11-a-side">11-a-side</option>
            <option value="9-a-side">9-a-side</option>
            <option value="7-a-side">7-a-side</option>
            <option value="5-a-side">5-a-side</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="form__item">
          <label class="form__label" for="selectpitchType"
            >Select Pitch type</label
          >
          <select v-model="pitchData.pitchType" id="selectpitchType">
            <option disabled value="">Please select a pitch type</option>
            <option value="4G">4G</option>
            <option value="3G">3G</option>
            <option value="Grass">Grass</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="address">Location address</label>
          <input
            id="address"
            type="text"
            v-model="pitchData.address"
            name="address"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="postCode">Location postCode</label>
          <input
            id="postCode"
            type="text"
            v-model="pitchData.postCode"
            name="postCode"
          />
        </div>
        <div class="form__item">
          <button @click="addPitchCall()" class="green-button">
            Add pitch
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="editAPitch"
      size="lg"
      title="edit a Pitch"
      :hide-footer="Boolean(true)"
    >
      <div class="form">
        <div class="form__item">
            <label class="form__label" for="selectpitchType"
              >Select Pitch</label
            >
            <select v-model="editPitchData" id="selectpitchType">
              <option disabled value="">Please select a pitch</option>
            <option v-for="pitch in allTeamsEvents" :key="pitch.id" :value="pitch">
              {{ pitch.name }}</option>
            </select>
          </div>

          <div v-if="editPitchData.name !== undefined">
            
            <div class="form__item">
          <label class="form__label" for="name">Pitch name</label>
          <input
            class="form__input"
            type="text"
            v-model="editPitchData.name"
            id="name"
          />
        </div>
            <div class="form__item">
          <label class="form__label" for="address">Location address</label>
          <input
            id="address"
            type="text"
            v-model="editPitchData.address"
            name="address"
          />
        </div>
        <div class="form__item">
          <label class="form__label" for="postCode">Location postCode</label>
          <input
            id="postCode"
            type="text"
            v-model="editPitchData.postCode"
            name="postCode"
          />
        </div>

        <div class="form__item">
          <label class="form__label" for="selectpitchSize"
            >Select Pitch size</label
          >
          <select v-model="editPitchData.pitchSize" id="selectpitchSize">
            <option disabled value="">Please select a pitch size</option>
            <option value="11-a-side">11-a-side</option>
            <option value="9-a-side">9-a-side</option>
            <option value="7-a-side">7-a-side</option>
            <option value="5-a-side">5-a-side</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div class="form__item">
          <label class="form__label" for="selectpitchType"
            >Select Pitch type</label
          >
          <select v-model="editPitchData.pitchType" id="selectpitchType">
            <option disabled value="">Please select a pitch type</option>
            <option value="4G">4G</option>
            <option value="3G">3G</option>
            <option value="Grass">Grass</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="form__item">
          <button @click="editPitchCall()" class="green-button">
            edit pitch
          </button>
        </div>


          </div>

      </div>
    </b-modal>

    <b-modal
      id="addEditProgramme"
      size="lg"
      :title="selectedGame.name"
      :hide-footer="Boolean(true)"
    >
    </b-modal>

    <b-modal
      id="viewGameDetails"
      size="lg"
      :title="selectedGame.teamName"
      :hide-footer="Boolean(true)"
    >
      <h2>
        {{ selectedGame.gameType }}
        <span v-if="selectedGame.gameType !== 'Training'"
          >V {{ selectedGame.opposition }}</span
        >
        - {{ setPitchZoneText(selectedGame) }}
      </h2>
      <p>
        <span v-if="selectedGame.gameType !== 'Training'"
          ><b-icon icon="calendar2-day" aria-hidden="true"></b-icon> Kick
          off:</span
        >
        <b-icon
          v-if="selectedGame.gameType === 'Training'"
          icon="alarm"
          aria-hidden="true"
        ></b-icon>
        {{ getDayAndDate(selectedGame.matchDate) }}
        <span v-if="selectedGame.gameType !== 'Training'"> at </span
        >{{ formatISOStringDateAndTime(selectedGame.kickOffTime) }}
        <span v-if="selectedGame.gameType === 'Training'"
          >- {{ formatISOStringDateAndTime(selectedGame.endTime) }}</span
        ><br />
        <span v-if="selectedGame.gameType !== 'Training'"
          ><b-icon icon="alarm" aria-hidden="true"></b-icon> Report for:
          {{ formatISOStringDateAndTime(selectedGame.meetTime) }}</span
        >
      </p>

      <span class="venue-text"
        ><b-icon icon="map" aria-hidden="true"></b-icon> Venue:
        {{ selectedGame.address }} {{ selectedGame.postCode }}</span
      >
      <p class="padding-left">
        {{ selectedGame.locationName }} -
        <a
          class="map-link"
          v-if="selectedGame.postCode !== ''"
          :href="`https://maps.google.fr/maps?q=${selectedGame.postCode}`"
          target="_blank"
          ><b-icon icon="pin-map" aria-hidden="true"></b-icon> Map</a
        >
      </p>
      <p>
        <b-icon icon="grid3x3 " aria-hidden="true"></b-icon> Pitch type:
        {{ selectedGame.pitchType }}
      </p>
      <p v-if="selectedGame.gameType !== 'Training'">
        <b-icon icon="person" aria-hidden="true"></b-icon> Strip:
        {{ selectedGame.stripType }}
      </p>
      <p v-if="selectedGame.notes != ''">
        <b-icon icon="chat-right-text" aria-hidden="true"></b-icon>
        {{ selectedGame.notes }}
      </p>

      <div v-if="userData.userType === 'Admin'" class="player-game-numbers">
        <h3>Responses</h3>
        <ul>
          <li v-for="(attendee, index) in selectedGameAttendees" :key="index">
            <p>
              {{ attendee.name }}
              <span class="going" v-if="attendee.going == '1'"
                ><b-icon icon="hand-thumbs-up" aria-hidden="true"></b-icon>
                going</span
              >
              <span class="not-going" v-else-if="attendee.notGoing == '1'"
                ><b-icon icon="hand-thumbs-down" aria-hidden="true"></b-icon> No
                can't make it</span
              >
              <span class="no-response" v-else>No Response</span>
            </p>
          </li>
        </ul>
      </div>
    </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { CHECK_PROFILE_HAS_DATA } from "@/store/actions/user.js";
import { DayPilot, DayPilotScheduler } from "daypilot-pro-vue";
import { Games } from "@/services/games-service.js";
import { Users } from "@/services/user-service.js";
import { Teams } from "@/services/teams-service.js";
import { Pitches } from "@/services/coaches-pitches.js";
import moment from "moment";
import removeDuplicatesMixin from "@/mixin/removeDuplicates.js";
import setPitchZoneTextMixin from "@/mixin/pitchZoneText.js";

export default {
  name: "Scheduler",
  mixins: [removeDuplicatesMixin, setPitchZoneTextMixin],
  data: function () {
    return {
      pitchData: {},
      editPitchData: {},
      clubTeams: [],
      userData: {
        name: "",
        userType: "",
      },
      allselectedTeamsToggle: true,
      selectedTeam: {
        id: null,
      },
      allTeamsEvents: [],
      selectedGameAttendees: [],
      selectedGame: {},
      teamGamesDataUnfilterded: [],
      teamGamesData: [],
      editMode: false,
      listOfSelectedTeamIds: [],
      listOfSelectedTeamIdsToRemove: [],
      config: {
        timeHeaders: [
          { groupBy: "Day", format: "dd MMMM yyyy" },
          { groupBy: "Hour" },
        ],
        rowHeaderColumns: [{ name: "Name" }],
        scale: "Hour",
        days: DayPilot.Date.today().daysInMonth(),
        startDate: DayPilot.Date.today(),
        timeRangeSelectedHandling: "Enabled",
        eventHeight: 60,
        cellWidth: 100,
        onTimeRangeSelected: async (args) => {
          const dp = this.scheduler;
          const modal = await DayPilot.Modal.prompt(
            "Create a new event:",
            "Event 1"
          );
          dp.clearSelection();
          if (modal.canceled) {
            return;
          }
          dp.events.add({
            start: args.start,
            end: args.end,
            id: DayPilot.guid(),
            resource: args.resource,
            text: modal.result,
          });
        },
        onBeforeEventRender: (args) => {
          if (args.data.color !== undefined) {
            args.data.barColor = args.data.color;
          }
        },
        eventMoveHandling: "Disabled",
        onBeforeRowHeaderRender: (args) => {},
        onEventMoved: (args) => {
          this.message("Event moved: " + args.e.data.text);
        },
        eventResizeHandling: "Disabled",
        onEventResized: (args) => {
          this.message("Event resized: " + args.e.data.text);
        },
        eventClickHandling: "Enabled",
        onEventClicked: (args) => {
          this.showGameDetails(args.e.data);
        },
        eventHoverHandling: "Disabled",
        zoomLevels: [
          {
            name: "Day",
            properties: {
              startDate: function (args) {
                return args.date.getDatePart();
              },
              days: function () {
                return 2;
              },
            },
          },
          {
            name: "Week",
            properties: {
              startDate: function (args) {
                return args.date.firstDayOfWeek();
              },
              days: function () {
                return 7;
              },
            },
          },
        ],
      },
    };
  },
  props: {},
  components: {
    DayPilotScheduler,
  },
  computed: {
    ...mapGetters(["getProfile"]),
    scheduler: function () {
      return this.$refs.scheduler.control;
    },
  },
  async created() {
    this.selectedTeam.id = 1;
    const response = await this.$store.dispatch(CHECK_PROFILE_HAS_DATA);

    if (response === true) {
      this.setUser();
      this.loadResources();
    } else {
      const clientId = JSON.parse(
        localStorage.getItem("the_w_selectedClientId")
      );
      this.getUserById(clientId);
    }
  },
  methods: {
    select() {
      this.allselectedTeamsToggle = false;
    },
    selectedAllTeamsToggle() {
      this.allselectedTeamsToggle = !this.allselectedTeamsToggle;
    },
    applyTeamsSelectedFliter() {
      var result = this.teamGamesDataUnfilterded.filter((o1) => {
        return this.listOfSelectedTeamIds.some((o2) => {
          return o1.teamId == o2;
      });
    });

      this.teamGamesData = this.addCalanderValues(result);
      this.scheduler.update({ events: this.teamGamesData });

    },
    changeSelectedTeam() {
      this.applyTeamsSelectedFliter();
    },
    async getAllGamesEventsByClubId() {
      const data = {
          action: "getAllGamesEventsByClubId",
          season_start_date:  moment().subtract(1, "months").format("YYYY-MM-DD"),
          season_end_date: moment().add(3, "months").format("YYYY-MM-DD")
        };

        const responce = await Games.find(data).catch((error) => {
          console.log(error);
        });

        if (responce.status === 200) {
          this.teamGamesDataUnfilterded = responce.data;
          this.teamGamesData = this.addCalanderValues(responce.data);
        this.loadReservations();
        }
    },
    async getTeamsByUserId() {
        const data = {
          action: "getTeamsDataById",
          clubId: this.userData.clubId,
        };
        const responce = await Teams.find(data).catch((error) => {
          console.log(error);
        });
        this.clubTeams = this.removeDuplicates(responce.data, "id");
        this.populateSelectedTeams();
      
    },
    populateSelectedTeams() {
      this.listOfSelectedTeamIds = this.clubTeams.map(clubTeam => clubTeam.id);
    },
    editAPitch() {
      this.editPitchData = {};
      this.$bvModal.show("editAPitch");
    },
    addAPitch() {
      this.pitchData = {};
      this.$bvModal.show("addAPitch");
    },
    async editPitchCall() {
      const userData = {
        ...this.editPitchData,
        clubId: this.userData.clubId,
        action: "editPitch",
      };

      const responce = await Pitches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.loadResources();
        this.$bvToast.toast("Pitch edited.", {
          title: "Pitch edited",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.$bvModal.hide("editAPitch");
      }

    },
    async addPitchCall() {
      const userData = {
        ...this.pitchData,
        clubId: this.userData.clubId,
        action: "addPitch",
      };
      const responce = await Pitches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data === "New pitch added.") {
        this.loadResources();
        this.$bvToast.toast("Pitch added.", {
          title: "Added",
          toaster: "b-toaster-bottom-right",
          solid: true,
          appendToast: true,
          variant: "success",
        });
        this.$bvModal.hide("addAPitch");
      }
    },
    async getUserById(clientId) {
      const userData = {
        clientId: clientId,
        action: "getUserByID",
      };
      const responce = await Users.find(userData).catch((error) => {
        console.log(error);
      });
      if (responce.data.length > 0) {
        this.userData = responce.data[0];
        this.loadResources();
      }
    },
    setUser() {
      if (this.getProfile !== null) {
        this.userData = this.getProfile;
      }
    },
    addGameTypeColours(event) {
      if (event.gameType === "League game") {
        event.color = "#019e53";
      }

      if (event.gameType === "Training") {
        event.color = "#1155cc";
      }

      if (event.gameType === "Cup game") {
        event.color = "#fdc400";
      }

      return event;
    },
    addCalanderValues(data) {
      return data.map((event) => {
        if (event.endTime === "") {
          let date = new Date(event.kickOffTime);
          date.setHours(date.getHours() + 2);
          event.endTime = date.toISOString();
        }
        if (event.pitch_id !== "" && event.pitch_id !== "0") {
          event.game_id = event.id;
          event.resource = event.pitch_id;
        }

        let eventWithClours = this.addGameTypeColours(event);
        let pitchZone = this.setPitchZoneText(event);

        return {
          ...eventWithClours,
          start: eventWithClours.kickOffTime,
          end: eventWithClours.endTime,
          text: `${eventWithClours.gameType} - ${pitchZone}`,
        };
      });
    },
    showGameDetails(data) {
      this.selectedGame = data;
      this.getAllAttendeesForAGame();
      this.$bvModal.show("viewGameDetails");
    },
    loadReservations() {
      this.scheduler.update({ events: this.teamGamesData });
    },
    async loadResources() {
      const userData = {
        clubId: this.userData.clubId,
        action: "getAllPitchesForClub",
      };
      const responce = await Pitches.find(userData).catch((error) => {
        console.log(error);
      });

      if (responce.data.length > 0) {
        this.allTeamsEvents = responce.data;
        this.scheduler.update({ resources: responce.data });
        this.getTeamsByUserId();
        this.getAllGamesEventsByClubId();
      }
    },
    getStartOfTheWeek() {
      const today = new Date();
      const dateData = new Date(
        today.setDate(today.getDate() - today.getDay())
      );

      this.scheduler.startDate = dateData.toISOString().slice(0, 10);
      this.scheduler.update();
    },
    switchToWeekView() {
      this.scheduler.zoom.setActive(1);
      this.scheduler.update();
    },
    switchToDayView() {
      this.scheduler.zoom.setActive(0);
      this.scheduler.update();
    },
    changeWeek(amout) {
      this.scheduler.startDate = this.scheduler.startDate.addDays(amout);
      this.scheduler.update();
    },
    getDayAndDate(date) {
      return moment(date).format("dddd, Do");
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    checkUserIsAttendingGame() {
      let responce = false;
      if (this.selectedGameAttendees.length > 0) {
        this.selectedGameAttendees.filter((game) => {
          if (String(game.user_id) === String(this.userData.id)) {
            switch (game.going) {
              case "1":
                responce = true;
                break;
              case "0":
                responce = false;
                break;
            }
          }
        });
      }
      return responce;
    },
    async getAllAttendeesForAGame() {
      const userData = {
        userId: this.userData.id,
        kickOffTime: this.selectedGame.kickOffTime,
        teamId: this.selectedGame.teamId,
        action: "getAllAttendeesForAGame",
      };
      const responce = await Games.find(userData).catch((error) => {
        console.log(error);
      });
      this.selectedGameAttendees = responce.data;
    },
  },
  mounted: function () {
    this.scheduler.businessBeginsHour = 8;
    this.scheduler.businessEndsHour = 23;
    this.scheduler.showNonBusiness = false;
    this.scheduler.businessWeekends = true;
  },
};
</script>

<style scoped lang="scss">

::v-deep .dropdown-menu {
max-height: 200px;
width:20rem;
overflow-y: auto;
box-shadow: 0 11px 16px rgba(138, 138, 138, 0.3);

div {
  border-bottom: 1px solid silver;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;

    input {
      margin-right: 0.5rem;
    }
}
}

.filter-small {
    height: 2.5rem;
    border: transparent;
    color: #9ea7b0;

    &:hover, &:focus {
    background: transparent;
    color: #7c838a;
    border: transparent;
    outline: transparent;
    -webkit-box-shadow: 0 0 0 0rem;
    box-shadow: 0 0 0 0rem;
}
}

.tabs-wrapper {
  padding: 2rem;
  overflow: hidden;
  
  button:first-of-type {
    margin-right: 0.5rem;
  }

  .edit-pitch-button {
    margin-top: 0rem;
    padding: 1rem;
  }
}
</style>
