<template>
  <div class="space">
    <div class="form__item">
      <label
        v-if="showHomeTeamsPiches === true"
        class="form__label"
        for="selectpitchType"
        >Select Pitch</label
      >
      <label v-else class="form__label" for="selectpitchType"
        >Add a Pitch</label
      >
      <select
        v-if="showHomeTeamsPiches === true"
        id="selectpitch"
        v-model="pitch_id"
        @change="pitchChangeCall($event)"
      >
        <option disabled value="">Please select a pitch</option>
        <option v-for="pitch in pitches" :value="pitch.id" :key="pitch.id">
          {{ pitch.name }}
        </option>
      </select>

      <a
        v-if="showHomeTeamsPiches === true"
        @click="defaultPitchInputsCall('newGameData')"
        href="javascript:;"
        >Or enter new pitch details</a
      >
      <a v-else @click="showHomeTeamsPiches = true" href="javascript:;"
        >View teams Piches</a
      >
    </div>
    <div
      v-if="gameData.matchDate !== '' && pitch_id !== '' && gameData.gameType === 'Training'"
      class="pitch-avaiability"
    >
      <p>Booked times</p>
      <p
        v-for="game in selectedGameTrainingEvent"
        :value="game.id"
        :key="game.id"
      >
        <span
          class="booked-time"
          v-if="
            game.pitch_id === pitch_id &&
            game.matchDate === formatMatchDate(gameData.matchDate)
          "
          >{{ getDayAndDate(game.matchDate) }}
          {{ formatISOStringDateAndTime(game.kickOffTime) }}
          {{ checkEndTime(game.kickOffTime, game.endTime) }} -
          {{ setPitchZoneText(game) }}</span
        >
      </p> 

      <div
        v-if="showPitchSectionsSelectInfo === true && selectedPitch[0].pitch_size === '11-a-side'"
        class="pitchSectionsSelectInfo"
      >
        <p>Select full pitch or sections of the pitch.</p>
        <div
          v-if="
            pitchBookedState.sectionOne === false ||
            pitchBookedState.sectionTwo === false ||
            pitchBookedState.sectionThree === false 
          "
        >
          <input
            @change="pitchSelectToggle('fullPitch', pitch.pitchId)"
            type="checkbox"
            id="fullPitch"
            name="fullPitch"
            :checked="pitch.fullPitch"
          />
          <label for="fullPitch">Select full pitch</label><br />
        </div>

        <div v-else
        ><input
            type="checkbox"
            id="fullPitch"
            name="fullPitch"
            :checked="false"
            disabled="true"
          />
          <label for="fullPitch">Select full pitch</label><br />
        </div>

        <img src="../../views/img/pitch.svg" alt="" />

        <div v-if="pitchBookedState.sectionOne === true"
          class="areaSelected pitchSectionSelector selectonOne"
        >
          <div>
            <input
              type="checkbox"
              id="selectonOne"
              name="selectonOne"
              :checked=true
              disabled="true"
            />
            <label for="selectonOne">Zone one</label><br />
          </div>
        </div>
        <div v-else
          :class="
            pitch.sectionOne === true
              ? 'areaSelected pitchSectionSelector selectonOne'
              : 'pitchSectionSelector selectorOne'
          "
        >
          <div>
            <input
              @change="pitchSelectToggle('sectionOne', pitch.pitchId)"
              type="checkbox"
              id="selectonOne"
              name="selectonOne"
              :checked="pitch.sectionOne"
            />
            <label for="selectonOne">Zone one</label><br />
          </div>
        </div>


        <div v-if="pitchBookedState.sectionTwo === true"
          class="areaSelected pitchSectionSelector selectonTwo"
        >
          <div>
            <input
              type="checkbox"
              id="selectonTwo"
              name="selectonTwo"
              :checked=true
              disabled="true"
            />
            <label for="selectonTwo">Zone two</label><br />
          </div>
        </div>

        <div v-else
          :class="
            pitch.sectionTwo === true
              ? 'areaSelected pitchSectionSelector selectonTwo'
              : 'pitchSectionSelector selectonTwo'
          "
        >
          <div>
            <input
              @change="pitchSelectToggle('sectionTwo', pitch.pitchId)"
              type="checkbox"
              id="selectonTwo"
              name="selectonTwo"
              :checked="pitch.sectionTwo"
            />
            <label for="selectonTwo">Zone Two</label><br />
          </div>
        </div>

        <div v-if="pitchBookedState.sectionThree === true"
          class="areaSelected pitchSectionSelector selectonThree"
        >
          <div>
            <input
              type="checkbox"
              id="selectonThree"
              name="selectonThree"
              :checked=true
              disabled="true"
            />
            <label for="selectonThree">Zone two</label><br />
          </div>
        </div>

        <div v-else
          :class="
            pitch.sectionThree === true
              ? 'areaSelected pitchSectionSelector selectonThree'
              : 'pitchSectionSelector selectonThree'
          "
        >
          <div :class="{ areaSelected: pitch.sectionThree }">
            <input
              @change="pitchSelectToggle('sectionThree', pitch.pitchId)"
              type="checkbox"
              id="selectonThree"
              name="selectonThree"
              :checked="pitch.sectionThree"
            />
            <label for="selectonThree">Zone Three</label><br />
          </div>
        </div>
      </div>


    </div>
    <div v-else>
      <p v-if="showHomeTeamsPiches === true">
        Choose a date and pitch to see pitch avaiablity.
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import setPitchZoneTextMixin from "@/mixin/pitchZoneText.js";
import { Games } from "@/services/games-service.js";
export default {
  name: "PitchSelet",
  mixins: [setPitchZoneTextMixin],
  data: function () {
    return {
      pitch_id: "",
      showHomeTeamsPiches: true,
      showPitchSectionsSelectInfo: false,
      pitch: {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
        fullPitch: false,
        pitchId: null,
        gameType: null,
      },
      pitchBookedState: {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
        fullPitch: false,
        pitchId: null,
        gameType: null,
      },
      selectedGameTrainingEvent: [],
      selectedPitch: [{ pitch_size: null }],
    };
  },
  props: {
    pitches: {
      type: Array,
      default: null,
    },
    gameData: {
      type: [Object],
      default: null,
    },
  },
  created() {
    this.resetPitchSelectionValaues("pitch");
    this.resetPitchSelectionValaues("pitchBookedState");
  },
  methods: {
    setSelectedPitchZones(pitchZoneData, type) {

      if (pitchZoneData.includes("1")) {
        this[type].sectionOne = true;
      }


      if (pitchZoneData.includes("2")) {
        this[type].sectionTwo = true;
      }

      if (pitchZoneData.includes("3")) {
        this[type].sectionThree = true;
      }

      if (pitchZoneData.includes("1,2")) {
        this[type].sectionOne = true;
        this[type].sectionTwo = true;
      }

      if (pitchZoneData.includes("2,3")) {
        this[type].sectionTwo = true;
        this[type].sectionThree = true;
      }

      if (pitchZoneData.includes("1 2,3")) {
        this[type].sectionOne = true;
        this[type].sectionTwo = true;
        this[type].sectionThree = true;
        this[type].fullPitch = true;
      }

      if (pitchZoneData.includes("fullPitch")) {
        this[type].sectionOne = true;
        this[type].sectionTwo = true;
        this[type].sectionThree = true;
        this[type].fullPitch = true;
      }
    },
    pitchSelectToggle(type) {
      console.log(this.gameData);
      if (type === "fullPitch") {
        this.pitch.fullPitch === true
          ? this.resetPitchSelectionValaues("pitch")
          : (this.pitch = {
              sectionOne: true,
              sectionTwo: true,
              sectionThree: true,
              fullPitch: true,
              pitchId: this.gameData.pitch_id,
              gameType: this.gameData.id === true ? 'editGameData' : 'newGameData',
            });
      } else {
        this.pitch[type] = !this.pitch[type];
      }
      this.$emit("pitchChange", this.pitch, this.pitch.gameType);
    },
    resetPitchSelectionValaues(type) {
      this[type] = {
        sectionOne: false,
        sectionTwo: false,
        sectionThree: false,
        fullPitch: false,
        pitchId: null,
      };
    },
    async callToGetAllBookingsForSelectedPitch() {
      let matchDta = this.formatMatchDate(
        this.gameData.matchDate
        );
        let kickOffTime =  this.changeTimeToTimeAndDate(
          this.gameData.kickOffTime,
          matchDta
        );
        
      let endTime = this.changeTimeToTimeAndDate(
        this.gameData.endTime,
        matchDta
      );

      let data = {
        pitchId: this.pitch.pitchId,
        gameDate: matchDta,
        kickOffTime: kickOffTime,
        endTime: endTime,
        action: "getAllGamesEventsByPitchIdOnDateTime",
      };

      const responce = await Games.find(data).catch((error) => {
        console.log(error);
      });

      if (responce.status === 200) {
        this.selectedGameTrainingEvent = responce.data;
        this.setPitchBookedState();
      }
    },
    setPitchBookedState() {
      let data = this.selectedGameTrainingEvent.map((event) => event.pitch_zone);
      this.setSelectedPitchZones(data, "pitchBookedState");
    },
    pitchChangeCall(e) {
      console.log(this.gameData.id);
      let gameType = this.gameData.id === undefined ? "newGameData" : "editGame";
      this.resetPitchSelectionValaues("pitchBookedState");
      this.pitch.pitchId = this.pitch_id;
      this.pitch.gameType = gameType;

      this.selectedPitch = this.pitches.filter(pitch => pitch.id === this.pitch_id);

      this.callToGetAllBookingsForSelectedPitch();

      this.$emit("pitchChange", this.pitch, gameType);
      this.showPitchSectionsSelectInfo = true;
    },
    defaultPitchInputsCall(gameType) {
      this.showHomeTeamsPiches = false;
      this.$emit("default-pitch-inputs", gameType);
    },
    getDayAndDate(date) {
      return moment(date).format("dddd, Do");
    },
    formatISOStringDateAndTime(timeDateString) {
      return moment(timeDateString).utc().format("h:mm a");
    },
    formatMatchDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    changeTimeToTimeAndDate(timeString, date) {
      return `${date}T${timeString}:00.000Z`;
    },
    checkEndTime(kickOffTime, endTime) {
      return endTime === ""
        ? this.formatISOStringDateAndTime(moment(kickOffTime).add(2, "hours"))
        : this.formatISOStringDateAndTime(endTime);
    },
  },
};
</script>

<style scoped lang="scss">
.pitch-avaiability {
  border: 1px solid #dddddd;
  padding: 1rem;
  overflow-y: scroll;
  background: #fafafa;

  .booked-time {
    border: 1px solid red;
    background: #ffe8e8;
    display: block;
    padding: 0.5rem;
  }
}

.pitchSectionsSelectInfo {
  position: relative;
  padding: 2rem;
  background: #f4fff5;

  label {
    margin-left: 0.3rem;
  }

  .pitchSectionSelector {
    position: absolute;
    height: 69%;
    border: 1px dashed #93a2bb;
    top: 96px;
    left: 36px;
    width: 8.3rem;
    text-align: center;
    padding-top: 1rem;

    &:hover,
    &:focus {
      cursor: pointer;
      background: rgba(198, 234, 202, 0.5);
    }
  }

  .areaSelected {
    background: rgba(198, 234, 202, 0.5);
  }

  .selectonTwo {
    left: 11.6rem;
    border-top: none;
    border-bottom: none;
    border-left: 1px dashed #93a2bb;
    border-right: 1px dashed #93a2bb;
  }

  .selectonThree {
    left: 21rem;
  }
}
</style>
