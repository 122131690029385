import Axios from "axios";
import { Storage } from "./storage";
import store from "../store";

const HTTP = Axios.create({
    crossDomain: true,
    timeout: 10000,
    params: {}
});

HTTP.interceptors.response.use(
    success => {
        return success;
    },
    error => {
        return Promise.reject(error);
    }
);

HTTP.interceptors.request.use(config => {
    // config.headers.Authorization = `Bearer ${store.getters["auth/token"]}`;
    return config;
});

export { HTTP };
