<template>
  <div>
  <div class="main-session" v-if="showifOver2011()">
    <div class="wrapper-mins-stuff">

      <div class="mins-from-session">
      <span>Mins from session</span>
      <p>{{selectedDayPlayerData.session_mins}} <span>mins</span></p>
    </div>

      <div class="mins-from-session">
      <span>Distance covered</span>
      <p>{{selectedDayPlayerData.session_distance}} <span>Meters</span></p>
    </div>

    <div class="mins-from-session">
      <span>High Speed Running Distance (V4 + V5 + V6)</span>
      <p>{{selectedDayPlayerData.total_absolute_high_speed_distance}} <span>Meters</span></p>
    </div>

    <div class="mins-from-session">
      <span>Meterage per minute</span>
      <p>{{ selectedDayPlayerData.meterage_per_minute }} <span></span></p>
    </div>

    <div class="mins-from-session">
      <span>Max vel max</span>
      <p>{{ selectedDayPlayerData.max_vel_max }}</p>
    </div>

    <div class="mins-from-session">
      <span>Accelerations total efforts</span>
      <p>{{ selectedDayPlayerData.accelerations_total_efforts }} <span></span></p>
    </div>

    <div class="mins-from-session">
      <span>decelerations_total_efforts</span>
      <p>{{ selectedDayPlayerData.decelerations_total_efforts }} <span></span></p>
    </div>

    <div class="mins-from-session">
      <span>Maximum velocity</span>
      <p>{{ selectedDayPlayerData.maximum_velocity }} <span></span></p>
    </div>

    </div>
  </div>

  <div v-else class="main-session">

    <div class="mins-from-session">
          <span>Mins from session</span>
          <p>{{selectedDayPlayerData.session_mins}} <span>mins</span></p>
        </div>

    <div class="wrapper-mins-stuff" v-for="(gpsData, index) in selectedPlayMakerGpsData"
        :value="gpsData.id"
        :key="gpsData.id + index">

        <div class="mins-from-session" style="min-width:100%; border: none;">
        <span>Session type</span>
        <p>{{gpsData.session_type}}</p>
      </div>


      <div class="mins-from-session">
        <span>Phase Duration</span>
        <p>{{gpsData.phase_duration_min}} <span>mins</span></p>
      </div>

      <div class="mins-from-session">
        <span>Player Participation Time</span>
        <p>{{gpsData.player_participation_time_min}} <span>mins</span></p>
      </div>

      <div class="mins-from-session">
        <span>Tag</span>
        <p>{{gpsData.tag}}</p>
      </div>

      <div class="mins-from-session">
        <span>Distance Covered</span>
        <p>{{gpsData.distance_covered}} <span>m</span></p>
      </div>

      <div class="mins-from-session">
        <span>Left Leg Touches</span>
        <p>{{gpsData.left_leg_touches}}</p>
      </div>

      <div class="mins-from-session">
        <span>Right Leg Touches</span>
        <p>{{gpsData.right_leg_touches}}</p>
      </div>

      <div class="mins-from-session">
        <span>Total Touches</span>
        <p>{{gpsData.total_touches}}</p>
      </div>

      <div class="mins-from-session">
        <span>Releases</span>
        <p>{{gpsData.releases}}</p>
      </div>

      <div class="mins-from-session">
        <span>Releases Right</span>
        <p>{{gpsData.releases_right}}</p>
      </div>

      <div class="mins-from-session">
        <span>Releases Left</span>
        <p>{{gpsData.releases_left}}</p>
      </div>

      <div class="mins-from-session">
        <span>Work Rate</span>
        <p>{{gpsData.work_rate}}<span>mins</span></p>
      </div>

      <div class="mins-from-session">
        <span>Top Speed (m/s)</span>
        <p>{{gpsData.top_speed}}<span>m/s</span></p>
      </div>

    </div>


</div>

</div>

</template>

<script>
import { PlayerPhysicalPerformance } from "@/services/player-physical-performance-service.js";
export default {
  data() {
    return {
      selectedPlayMakerGpsData: [],
    };
  },
  props: {
    playerPlayMakerGpsData: {
      type: Array,
      default: null,
    },
    selectedDayPlayerData: {
      type: Object,
      default: null,
    },
    selectedUserData: {
      type: Object,
      default: null,
    }
  },
  created() {
    if (this.playerPlayMakerGpsData !== null) {
       this.selectedPlayMakerGpsData = this.getPlayMakerGpsDataSelected();
    }
  },
  methods: {
    getPlayMakerGpsDataSelected() {
      return this.playerPlayMakerGpsData.filter(gpsData => gpsData.session_date === this.selectedDayPlayerData.session_date)
    },
    showifOver2011() {
      if (this.selectedUserData.age_group !== '2016' 
      && this.selectedUserData.age_group !== '2015' 
      && this.selectedUserData.age_group !== '2014'
      && this.selectedUserData.age_group !== '2013'
      && this.selectedUserData.age_group !== '2012'
      && this.selectedUserData.age_group !== '2011'
    ) {

      return true;

      } else {
        return false;
      }
    },
  },
};

</script>

<style scoped lang="scss">

.player-stats-gps-wrapper {

.player-session-details {

  .player-name {
    font-size:2rem;
    font-weight:bold;
    margin:0;
  }

  .session-name {
    p {
      margin:0;

      span {
        width: 8rem;
  display: inline-block;
      }
    }
  }

  .main-session .wrapper-mins-stuff {
    
    background: #f2f5f7;
    display:flex;
    flex-wrap: wrap;
    gap:1rem;
    padding:1rem;

    .mins-from-session {
      padding:1rem;
      border: 1px solid #c7ccd0;
      min-width: 12rem;
      span {
      }

      p {
        font-size:3rem;
        font-weight:bold;
        line-height: 2.7rem;
        margin: 0;
        span {
          font-size:1rem;
        }
      }
    }

  }

}

}
</style>
