import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_POPULATE,
  CHECK_FOR_SELECTED_CLIENT_WITH_ID_WITH_LOCALSTORGE,
  UPDATE_SELECTED_CLIENT,
  GET_USER_WITH_ID,
  CHECK_PROFILE_HAS_DATA,
  POPULATE_WEEK_SELECTED_NUMBER,
  POPULATE_COACHES_USER_DATA,
  POPULATE_SELECTED_USER_DATA,
  POPULATE_SELECTED_CALENDAR_DATE,
  POPULATE_MON_TO_FRI_DATE
} from "../actions/user";
import { apiCall, api_routes } from "@/utils/api";
import { AUTH_LOGOUT } from "../actions/auth";
import { Storage } from "../../services/storage";

const state = {
  status: "",
  profile: {},
  selectedClientId: null,
  selectedWeek: null,
  weeklyDate: null,
  selectedUserValue: null,
  selectedWeekMonSunday: null,
  coachesUsersData: []
};

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.name,
  getWeekNumber: state => state.selectedWeek,
  getCoachesUsersData: state => state.coachesUsersData,
  getSelectedUserValue: state => state.selectedUserValue,
  getSelectedCalendarDateValue: state => state.weeklyDate,
  getMonToFri: state => state.selectedWeekMonSunday
};

const actions = {
  [POPULATE_MON_TO_FRI_DATE]: ({ commit, payload }) => {
    commit(POPULATE_MON_TO_FRI_DATE, payload);
  },
  [POPULATE_SELECTED_CALENDAR_DATE]: ({ commit }, payload) => {
    commit(POPULATE_SELECTED_CALENDAR_DATE, payload);
  },
  [POPULATE_SELECTED_USER_DATA]: ({ commit }, payload) => {
    commit(POPULATE_SELECTED_USER_DATA, payload);
  },
  [POPULATE_COACHES_USER_DATA]: ({ commit }, payload) => {
    commit(POPULATE_COACHES_USER_DATA, payload);
  },
  [POPULATE_WEEK_SELECTED_NUMBER]: ({ commit }, payload) => {
    commit(POPULATE_WEEK_SELECTED_NUMBER, payload);
  },
  [CHECK_PROFILE_HAS_DATA]: () => Object.keys(state.profile).length !== 0,
  [GET_USER_WITH_ID]: ({ commit }, payload) => {
    const userData = {
      clientId: payload,
      action: "getUserByID"
    };
    apiCall({
      url: api_routes.user.userEndPoint,
      data: userData,
      method: "post"
    })
      .then(response => {
        if (Array.isArray(response)) {
          commit(USER_POPULATE, response[0]);
        }
        resolve(response);
      })
      .catch(err => {
        // if resp is unauthorized, logout, too
        // dispatch(AUTH_LOGOUT);
      });
  },
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    apiCall({ url: api_routes.user.me })
      .then(resp => {
        commit(USER_SUCCESS, resp);
      })
      .catch(err => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, too
        dispatch(AUTH_LOGOUT);
      });
  },
  [USER_POPULATE]: ({ commit }, payload) => {
    commit(USER_POPULATE, payload.userData);
  },
  [CHECK_FOR_SELECTED_CLIENT_WITH_ID_WITH_LOCALSTORGE]: () => {
    const clientId = Storage.get("the_w_selectedClientId");
    if (clientId !== null) {
      commit(UPDATE_SELECTED_CLIENT, clientId);
    }
  }
};

const mutations = {
  [POPULATE_MON_TO_FRI_DATE](state, selectedWeekMonSunday) {
    state.selectedWeekMonSunday = selectedWeekMonSunday;
  },
  [POPULATE_SELECTED_CALENDAR_DATE](state, weeklyDateValue) {
    state.weeklyDate = weeklyDateValue;
  },
  [POPULATE_SELECTED_USER_DATA](state, selectedUserData) {
    state.selectedUserValue = selectedUserData;
  },
  [POPULATE_COACHES_USER_DATA](state, coachesUsersData) {
    state.coachesUsersData = coachesUsersData;
  },
  [POPULATE_WEEK_SELECTED_NUMBER](state, selectedWeek) {
    state.selectedWeek = selectedWeek;
  },
  [UPDATE_SELECTED_CLIENT](state, selectedClientId) {
    state.selectedClientId = selectedClientId;
    Storage.setJSON("selectedClientId", state.selectedClientId);
  },
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.profile = resp;
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  },
  [USER_POPULATE]: (state, resp) => {
    state.profile = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
