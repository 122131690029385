const Storage = {
    prefix: "the_w",

    set(key, data) {
        localStorage.setItem(this.getKey(key), data);
    },

    get(key) {
        return localStorage.getItem(this.getKey(key));
    },

    setJSON(key, data) {
        const json = JSON.stringify(data);
        this.set(key, json);
    },

    getJSON(key) {
        const data = this.get(key);
        return JSON.parse(data);
    },

    getKey(key) {
        console.log(key);
        return `${this.prefix}_${key}`;
    },

    remove(key) {
        if (Array.isArray(key)) {
            key.forEach(value => {
                localStorage.removeItem(this.getKey(value));
            });
        } else {
            localStorage.removeItem(this.getKey(key));
        }
    }
};

export { Storage };
