<template>
    <div class="two-col-pop">
      <div class="user-photo-info">
        <img
          v-if="
            selectedUserDataForUserImageAndInfo.userImage !== '' &&
            selectedUserDataForUserImageAndInfo.userImage !== null
          "
          :src="getImgUrl(selectedUserDataForUserImageAndInfo.userImage)"
          alt=""
        />
        <img v-else :src="getImgUrl('default-image.jpg')" alt="" />
        <CircularPercentage
          :review="playerPerformanceReportData"
          :allPlayerReviews="null"
          circularType="single"
        />
      </div>
      <div class="user-details-pop">
        <p><span>Name:</span> {{ selectedUserDataForUserImageAndInfo.name }}</p>
        <p><span>Phone:</span> {{ selectedUserDataForUserImageAndInfo.phone }}</p>
        <p><span>Email:</span> {{ selectedUserDataForUserImageAndInfo.email }}</p>
        <p>
          <span>DOB:</span> {{ formatDateForHuman(selectedUserDataForUserImageAndInfo.dob) }}</p>
        <p>
          <span>Position:</span> {{ selectedUserDataForUserImageAndInfo.position }}
        </p>
        <p>
          <span>Preferred foot:</span> {{ selectedUserDataForUserImageAndInfo.preferred_foot }}
        </p>
      </div>
    </div>
  </template>
  <script>
  import CircularPercentage from "../../components/CircularPercentage";
  import moment from "moment";
  export default {
    name: "userImageAndInfo",
    data() {
      return {};
    },
    components: { CircularPercentage },
    props: {
      selectedUserDataForUserImageAndInfo: {
        type: Object,
        default: null,
      },
      playerPerformanceReportData: {
        type: Object,
        default: null,
      },
    },
    methods: {
      getImgUrl(pic) {
        return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
      },
      formatDateForHuman(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    },
  };
  </script>
  <style scoped lang="scss">
  .user-details-pop p span {
    font-weight: bold;
    display: inline-block;
    min-width: 8rem;
}
  
  </style>
  