<template>
  <div class="form__item">
    <label class="form__label" for="imageURL">Upload image</label>
    <input
      accept="image/jpeg|image/jpg|image/heic|.jpeg"
      @change="imageChange($event, 'imageFile', 'image', 'fileOne')"
      type="file"
      class="form__input"
      ref="fileOne"
      id="imageURL"
    />
    <div v-if="image.path !== ''" class="image-preview-wrapper">
      <img class="image-preview" :src="getPath(image.path, 'image')" />
      <button @click="removeFileFromUI()" class="remove-file-button">
        Remove flie
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "imageUploader",
  data() {
    return {
      imageFile: null,
      imageToBeRemoved: null,
      editMode: false,
      image: {
        imageName: "",
        path: "",
        file: null,
      },
    };
  },
  props: {
    imageURL: {
      type: String,
      default: null,
    },
    imageFileData: {
      type: String,
      default: null,
    },
    imageObject: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    if (this.imageURL !== "" && this.imageURL !== null) {
      this.image.path = this.getImgUrl(this.imageURL);
    }
  },
  methods: {
    getImgUrl(pic) {
      return pic !== undefined && pic !== "" ? `/images/${pic}` : "";
    },
    imageChange(event, imageFileName, imageNumber, inputRef) {
      const file = event.currentTarget.files[0];
      this.createObjectURLCheck(file, imageFileName, imageNumber);
      this.image.file = file;
      this.image.imageName = file.name;
      this.$emit("populateImageObjectFromUpload", this.image);

      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.imageFile = event.target.result;
        this.$emit("populateImageFileDataFromUpload", this.imageFile);
      };
    },
    createObjectURLCheck(file, imageFileName, imageNumber) {
      if (file.type === "image/heic") {
        this.convertHeicToJpg(file, imageFileName, imageNumber);
      }
      "srcObject" in this
        ? (this.srcObject = file)
        : (this.image.path = URL.createObjectURL(file));
    },
    async convertHeicToJpg(file, imageFileName, imageNumber) {
      let base64 = URL.createObjectURL(file);
      fetch(base64)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob, toType: "image/jpeg" }))
        .then((conversionResult) => {
          let base64 = URL.createObjectURL(conversionResult);
          this[imageNumber].path = base64;
          this[imageFileName] = new File([conversionResult], "newimage", {
            type: "image/jpeg",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeFileFromUI() {
      (this.imageURL = ""),
        (this.image = {
          imageName: "",
          path: "",
          file: null,
        });
      this.$emit("clearImageDataFromUpload");
    },
    getPath(p, imageNumber) {
      if (p === undefined) {
        return;
      }
      return this.checkTheimageForBlob(p, imageNumber);
    },
    checkTheimageForBlob(p, imageNumber) {
      if (p !== null) {
        var URL = this.checkforDotAtTheStartOfUrl(p);
      }
      switch (typeof URL) {
        case "string":
          URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
          return URL.search("blob:") !== -1
            ? p
            : `${window.location.protocol}//${window.location.host}${URL}`;
        case "object":
          return this[imageNumber].path;
      }
    },
    checkforDotAtTheStartOfUrl(p) {
      return p.startsWith(".") ? p.substring(1) : p;
    },
  },
};
</script>
