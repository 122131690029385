<template>
  <div v-if="loadingStuff" class="loader-full-page">
    <div class="loading-box">
      <img src="/images/surf-logo.svg" alt="" />
      <span uk-spinner="ratio: 4.5"></span>
      <p>{{loadIngStuffText}}..</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {};
  },
  props: {
    loadingStuff: {
      type: Boolean,
      default: false
    },
    loadIngStuffText: {
      type: String,
      default: "Loading"
    }
  },
  methods: {}
};
</script>
